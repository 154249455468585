import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { postDataFromApi, putDataFromApi } from "../../Services/CommonServices";
import { deleteUserGroups, userGroup, userGroupAdd, userGroupUpdate, userManagementAdd, userManagementUpdate, vehicalGroupList } from "../../Services/api";
import { useTheme, styled } from "@mui/system";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Icon,
  TableRow,
  Button,
  Tooltip,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Autocomplete } from "@mui/lab";
import Switch from "react-switch";
import AlertMessage from "../commoncomponent/AlertMessage";
import Eye from "../../Assets/images/Vector.png";
import Trash from "../../Assets/images/trash.png";
import Pencil from "../../Assets/images/Pencil.png";
import User_IMG from "../../Assets/images/prof.png";
import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";
import { useNavigate } from "react-router-dom";
import {
  userManagementGet,
  userManagementList,
  userManagementDelete,
} from "../../Services/api";
import {
  getDataFromApi,
  deleteDataFromApi,
  imgUrl,
} from "../../Services/CommonServices";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { FcFolder } from "react-icons/fc";
import User_Icon from "../../Assets/images/user-icon.png";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { userList } from "../../Services/userManagement";
import { Loader } from "../../Common/Loader";
import AddUserManagement from "./add_user";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const AutoComplete = styled(Autocomplete)(() => ({
  width: 300,
  marginBottom: "16px",
}));
const status_opt = [
  { id: "Active", label: "Active" },
  { id: "Inactive", label: "Inactive" },
];

const userarray = [
  {
    User_ID: "IC125",
    User_Name: "John Snow",
    Mobile_Number: "(848) 339-8535",
    Email_ID: "johnsnow@nomail.com",
    User_Status: "Active",
  },
  {
    User_ID: "IC126",
    User_Name: "Abacus Pascal",
    Mobile_Number: "(848) 339-8535",
    Email_ID: "abacuspascal@gmail.com",
    User_Status: "Inactive",
  },
  {
    User_ID: "IC125",
    User_Name: "Grof Sethy",
    Mobile_Number: "(848) 339-85333",
    Email_ID: "grofsethy@nomail.com",
    User_Status: "Inactive",
  },
  {
    User_ID: "IC126",
    User_Name: "Carbon Dating",
    Mobile_Number: "(848) 339-8535",
    Email_ID: "carbdating@nomail.com",
    User_Status: "Inactive",
  },
  {
    User_ID: "IC125",
    User_Name: "Frech Sedardin",
    Mobile_Number: "(848) 339-8535",
    Email_ID: "Frechsedardin@nomail.com",
    User_Status: "Active",
  },
  {
    User_ID: "IC126",
    User_Name: "Lorem Ipsum",
    Mobile_Number: "(622) 758-2333",
    Email_ID: "LoremIpsumipsum@gmail.com",
    User_Status: "Inactive",
  },
  {
    User_ID: "IC125",
    User_Name: "Jamey Lobster",
    Mobile_Number: "(848) 339-8535",
    Email_ID: "jameylobster@nomail.com",
    User_Status: "Active",
  },
  {
    User_ID: "IC126",
    User_Name: "Figg Meldon",
    Mobile_Number: "(622) 758-2333",
    Email_ID: "figgmeldon@gmail.com",
    User_Status: "Active",
  },
  {
    User_ID: "IC125",
    User_Name: "Samuel Jackson",
    Mobile_Number: "(848) 339-8535",
    Email_ID: "samueljackson@nomail.com",
    User_Status: "Inactive",
  },
];

const User_Management = () => {
  const navigate = useNavigate();
  let { user_id } = useParams();
  const userCompany = localStorage.getItem("userCompany");
  const userRole = localStorage.getItem("userRole");
  const [userManagement, setuserManagement] = useState([]);
  const [userViewType, setUserViewType] = useState('Add')
  const [timeChanges, setTimeChanges] = useState(true)
  const [treeExpandedKeys, settreeExpandedKeys] = useState(["0-0", "0-1-1"]);
  const [getUserData, setGetUserData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [delete_id, setDeleteId] = useState("");
  const [alert, setalert] = useState(false);
  const [openUserPopUp, setopenUserPopup] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [alertType, setalertType] = useState("");
  const [val, setVal] = useState(false);
  const [groupName, setGroupName] = useState();
  const [selectedKeys, setSelectedKeys] = useState();
  const [getName, setGetName] = useState("");
  const [getId, setGetId] = useState();
  const [searchResult, setsearchResult] = useState([]);
  const [noSearchResultData, setnoSearchResultData] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState('')
  const [formdata, setFormData] = useState({ email: "", status: "" });
  const [loading, setLoad] = React.useState(true);
  function confirm() {
    setalert(false);
  }

  function handleDeleteClose() {
    setDeleteOpen(false);
    setDeleteId("");
  }
  function handleDeleteOpen(id) {
    setDeleteOpen(true);
    setDeleteId(id);
  }
  const handleDeleteConfirm = async (e) => {
    var query = "";
    var response = "";
    response = await deleteDataFromApi(userManagementDelete + delete_id + "/");

    if (response && response.status == 200) {
      setDeleteId("");
      setDeleteOpen(false);
      setalertMessage("User Deleted successfully");
      setalert(true);
      setalertType("success");
      getuserManagement();
    } else {
      setalertMessage("error...");
      setalert(true);
      setalertType("error");
      setDeleteId("");
      setDeleteOpen(false);
      getuserManagement();
    }
  };
  const handleSubmitFilter = async (event) => {
    // $("#usertable").DataTable().destroy();
    // setsearchResult([]);
    // setnoSearchResultData(false);
    var newSearchArr = [];
    if (formdata.email && formdata.status) {
      userManagement.find((opt) => {
        if (
          (opt.email &&
            opt.email.toLowerCase().includes(formdata.email.toLowerCase()) &&
            formdata.status == opt.status) ||
          (opt.user_name &&
            opt.user_name
              .toLowerCase()
              .includes(formdata.email.toLowerCase()) &&
            formdata.status == opt.status)
        ) {
          newSearchArr.push(opt);
        } else {
          setnoSearchResultData(true);
        }
      });
    } else if (formdata.email) {
      userManagement.find((opt) => {
        if (
          (opt.email &&
            opt.email.toLowerCase().includes(formdata.email.toLowerCase())) ||
          (opt.user_name &&
            opt.user_name.toLowerCase().includes(formdata.email.toLowerCase()))
        ) {
          newSearchArr.push(opt);
        } else {
          setnoSearchResultData(true);
        }
      });
    } else if (formdata.status) {
      userManagement.find((opt) => {
        if (formdata.status.toLowerCase() == opt.status.toLowerCase()) {
          newSearchArr.push(opt);
        } else {
          setnoSearchResultData(true);
        }
      });
    } else {
      setnoSearchResultData(false);
    }
    setsearchResult(newSearchArr);
  };
  const clearUserFilter = () => {
    setTimeChanges(false)
    setFormData({ email: '', status: '' });
    setsearchResult([]);
    setnoSearchResultData(false);
    setTimeout(() => {
      setTimeChanges(true)
    }, 1)
  }

  function formdataValueChange(e) {
    var value = e.target.value.trimStart();
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: value,
    }));
  }

  function changeDropdownValue(type, e) {
    if (e) {
      var value = e.id;
    } else {
      var value = "";
    }
    setFormData((formData) => ({
      ...formData,
      [type]: value,
    }));
  }

  function getSelectedItem(id, data = [], multiple = "", label = "") {
    const item = data.find((opt) => {
      if (label) {
        if (opt.label == id) return opt;
      } else {
        if (opt.id == id) return opt;
      }
    });
    if (multiple) {
      return item || [];
    } else {
      return item || null;
    }
  }

  const getuserManagement = async () => {
    setLoad(true)
    try {
      const chkRole = ["icam user", "icam admin"];
      var response = "";
      if (chkRole.indexOf(userRole?.toLowerCase()) !== -1) {
        response = await getDataFromApi(userManagementList);
      } else {
        response = await userList({ company_name: userCompany });
      }
      // var query = '';
      // axios.post('http://ec2-3-84-8-100.compute-1.amazonaws.com:8000/api/company/user_list_with_company_name/', { company_name: userCompany }).then((res) => {
      //   res.data.sort((a, b) => b.id - a.id);
      //   setuserManagement(res.data);
      //   getdatatable();
      // });
      if (response && response?.status == 200 && response?.data != null) {
        var res = response?.data?.sort((a, b) => b.id - a.id);
        setuserManagement(res);
        // getdatatable();
        setLoad(false)
      }
      else {
        setLoad(false)
      }
    } catch (error) { setLoad(false) }
  };
  const addUserGroup = async () => {
    // const response = await axios.post(
    //   `http://ec2-3-84-8-100.compute-1.amazonaws.com:8000/api/user/usergroup/`,
    //   { name: groupName }
    // );
    const response = await postDataFromApi(userGroupAdd, { name: groupName });
    getUserGroupData();
  };
  const getUserGroupData = async () => {
    var query = "";
    // const response = axios.get(`http://ec2-3-84-8-100.compute-1.amazonaws.com:8003/api/vehicle/vehiclegroup/`);
    const response = await getDataFromApi(userGroup);
    console.log("sfsf",response)
    if (response && response?.status == 200 && response?.data != null) {
      setGetUserData(response?.data);
    }
    // axios
    //   .get(
    //     `    http://ec2-3-84-8-100.compute-1.amazonaws.com:8000/api/user/usergroup/    `
    //   )
    //   .then((response) => {
    //     setGetUserData(response.data);
    //   });
  };
  const onRightClick = async (info) => {
    setVal(!val);
    setGetName(info.node.title);
    setGetId(info.node.id);
    setSelectedKeys({ selectedKeys: [info.node.props.eventKey] });
  };
  const updateUserGroup = async () => {
    const response = await putDataFromApi(`${userGroupUpdate}${getId}/`, { name: getName })
    // var config = {
    //   method: "put",
    //   data: { name: getName },
    // };
    // const response = await axios(
    //   `http://ec2-3-84-8-100.compute-1.amazonaws.com:8000/api/user/usergroup/${getId}/`,
    //   config
    // );
    setVal(false);
    setGetName("");
    getUserGroupData();
  };
  const deleteUserGroup = async () => {
    const response = await deleteDataFromApi(`${deleteUserGroups}${getId}/`);
    // const response = await axios.delete(
    //   `http://ec2-3-84-8-100.compute-1.amazonaws.com:8000/api/user/usergroup/${getId}/`
    // );
    setVal(false);
    getUserGroupData();
  };

  useEffect(() => {
    getuserManagement();
    getUserGroupData();
  }, [val]);

  const getdatatable = async () => {
    $(document).ready(function () {
      setTimeout(function () {
        $("#usertable").DataTable({
          pageLength: 20,
        });
      }, 1000);
    });
  };
  const [openAlert, setOpenAlert] = useState(false);
  const [data, setData] = useState('')
  const [toggle, setToggle] = useState({ checked: false });
  // const handleSwitchChange = async (user,user_id) => {
  //   handleSubmitSearch(user,user_id);
  // };

  const handleClickOpen = (user) => {
    setOpenAlert(true);
    setData(user)
  };

  const handleSubmitSearch = async () => {
    var status = data.status.toLowerCase() == "active" ? 'inactive' : 'active'
    var formData = new FormData();
    formData.append("user_id", data.user_id);
    formData.append("mobile_number", data.mobile_number ? data.mobile_number : 0);
    formData.append("user_name", data.user_name);
    formData.append("dob", data.dob);
    formData.append("email", data.email);
    formData.append("gender", data.gender);
    formData.append("country", data.country);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("zip_code", data.zip_code ? data.zip_code : 0);
    formData.append("address", data.address);
    formData.append("status", status);
    formData.append("role", data.role);
    formData.append("user_group", data.user_grouping || '');
    formData.append('company_name', data.company_name)
    formData.append("vehicle_group", data.vehicle_group || '');
    formData.append('company_name', data.company_name)
    formData.append('driver_group', '')
    if (!data.id) {
      formData.append(
        "user_photo",
        data.user_photo && data.user_photo.size
          ? data.user_photo
          : null
      );
    }
    else {
      formData.append('user_photo', data.image_name)
    }
    var response = "";
    response = await putDataFromApi(
      userManagementUpdate + data.id + "/",
      formData
    );
    if (response && response.status == 200) {
      setalertMessage("Status Changed successfully");
      setalert(true);
      setalertType("success");
      setOpenAlert(false)
    } else {
      setalertMessage("error...");
      setalert(true);
      setalertType("error");
    }
    getuserManagement();
  };
  const handleClose = () => {
    setOpenAlert(false);
  };
  // pagination code
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userManagement.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onExpand = async (e) => {
    var optarray = [];
    if (e && e.length >= 2) {
      settreeExpandedKeys(["0-0", e[e.length - 1]]);
    } else if (e && e.length == 1) {
      settreeExpandedKeys(["0-0"]);
    }
    if (e && e.length >= 2) {
      var lastid = e.length - 1;
      var newopt = e[lastid].split("-");
      newopt = JSON.parse(newopt[2]);
      //   var res = await getDataFromApi(driverGroup2 + newopt + "/");
      //   if (res && res.status == 200 && res.data != null) {
      //     setexpandedGroupsId(res.data);
      //   }
      // } else {
      //   setexpandedGroupsId([]);
      // }
    };
  }
  const onCloseUserPopup = () => {
    setalert(false);
    setopenUserPopup(false);
    setFormData((formData) => ({
      ...formData,
      user_group: {},
      email: '',
      company_name: '',
      vehicle_group: {},
      role: '',
      user_name: '',
    }));

  };
  return (
    <>
      {loading && <Loader />}
      <Box className="main-box mar-t-24 grey-bg pad-b-50">
        <Grid container spacing={3} className="main-grid">
          <Grid item md={6} className="main-title">
            <h4>USERS</h4>
          </Grid>
          <Grid item md={6} className="breadcrumb">
            <h6>
              <span className="pointer" onClick={() => navigate("/dashboard")}>
                Home /
              </span>{" "}
              User Management
            </h6>
          </Grid>
        </Grid>
        <AlertMessage
          alert={alert}
          alertMessage={alertMessage}
          confirm={confirm}
          alertType={alertType}
        />
        <ValidatorForm
          onSubmit={handleSubmitFilter}
          onError={() => null}
          className="search-form"
        >
          <Grid container spacing={2} className="search-form-main">
            <Grid item md={4} className="search-form-block">
              <label>Search</label>
              <TextField
                type="text"
                name="email"
                id="email"
                label="Search by name / email"
                placeholder="Search by name / email"
                value={formdata.email}
                onChange={(e) => formdataValueChange(e)}
                className="search-email gm-t-10 custom-input"
              />
            </Grid>
            <Grid item md={4} className="search-form-block">
              <label>Status</label>
              {timeChanges &&
                <AutoComplete
                  className="dropdown gm-t-10 w-100 mar-b-0"
                  fullWidth
                  options={status_opt}
                  /* value={formdata.Country} */
                  defaultValue={getSelectedItem(formdata.status, status_opt)}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => {
                    if (value === null) {
                      // Clear the formdata status value when the user clears the selection
                      setFormData((prevData) => ({ ...prevData, status: '' }));
                    } else {
                      changeDropdownValue("status", value);
                    }
                    // changeDropdownValue("status", value)
                  }
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="search-dropdown custom-input border-none"
                      label="Select"
                      name="status"
                      placeholder="Select"
                      value={formdata.status ? formdata.status : ""}
                    />
                  )}
                />}
            </Grid>
            <Grid item md={4} className="search-form-block align-self-center" sx={{ display: 'inline-flex' }}>
              <Button
                variant="contained"
                color="primary"
                className="whitebg custom-btn"
                type="submit"
                style={{ height: '40px', marginRight: '23px', marginTop: '7px' }}
              >
                Search
              </Button>
              <Button
                style={{ marginTop: "8px", height: '40px' }}
                variant="contained"
                color="primary"
                className="whitebg custom-btn"
                type="submit"
                onClick={() => clearUserFilter()}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
        <Grid container spacing={3} className="search-form-main">
          <Grid
            item
            md={12}
            className="search-form-block mar-t-24 text-align-right mar-b-24"
          >
            <Button
              variant="contained"
              color="primary"
              className="whitebg custom-btn"
              // onClick={() => navigate("/user_management/add_user")}
              onClick={() => {
                setopenUserPopup(true);
                setSelectedUserDetails('')
                setUserViewType('Add')
              }
              }
            >
              ADD USER
            </Button>
          </Grid>

        </Grid>
        <Grid container spacing={3} className="user-list-main">
          <Grid item md={12} className="user-list-block">
            <div className="user-list-start">
              <div className="user-list-top-bar">
                <Grid container spacing={3} className="search-form-main">
                  <Grid item md={6} className="main-title-user respo-w-fit">
                    <h4 className="font-14">LIST OF USERS</h4>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    className="view-all-user respo-w-fit respo-mar-l-auto"
                  >
                    {/* <h6 className="font-grey font-12 font-underline text-align-right">
                      View all  
                    </h6> */}
                  </Grid>
                </Grid>
              </div>
              <div className="user-list-bottom">
                <Grid container spacing={3} className="user-list-main-block">
                  <Grid item md={2} className="user-list-left h-500">
                    {val ? (
                      <>
                        <label>change</label>
                        <br />
                        <input
                          type={"text"}
                          value={getName}
                          onChange={(e) => setGetName(e.target.value)}
                        />
                        <Button
                          onClick={() => setVal(false)}
                          size="small"
                          color="primary"
                          type="button"
                        >
                          Back
                        </Button>
                        <div className="updateDelete">
                          <Button
                            onClick={updateUserGroup}
                            style={{
                              marginTop: "10px",
                              padding: "5px 10px",
                              backgroundColor: "#2E386B ",
                            }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Update
                          </Button>
                          <Button
                            onClick={deleteUserGroup}
                            style={{
                              marginTop: "10px",
                              marginLeft: "10px",
                              padding: "5px 10px",
                            }}
                            variant="contained"
                            color="error"
                            type="submit"
                          >
                            Delete
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Tree
                          defaultExpandedKeys={["0-0", "0-0-1"]}
                          expandedKeys={treeExpandedKeys}
                          expandAction="click"
                          onRightClick={onRightClick}
                          onExpand={onExpand}
                        >
                          <TreeNode
                            title="Event Action Groups"
                            key="0-0"
                            icon={<FcFolder />}
                          >
                            {getUserData.map((userGroup, index) => (
                              <>
                                <TreeNode
                                  title={userGroup.name}
                                  id={userGroup.id}
                                  key={`0-1-${index}`}
                                  icon={<FcFolder />}
                                >
                                  {userManagement
                                    .filter(
                                      (user) =>
                                        user.user_group &&
                                        user.user_group ==
                                        userGroup.name
                                    )
                                    .map((user, i) => (
                                      <TreeNode
                                        title={user.user_name}
                                        key={`0-0-${index}-${i}`}
                                        id={user.id}
                                        className="childTreenode"
                                      />
                                    ))}
                                </TreeNode>
                              </>
                            ))}
                          </TreeNode>
                        </Tree>
                        <form
                          onSubmit={(e) => {
                            // e.preventDefault();
                            groupName && addUserGroup();
                            setVal(false);
                            setGroupName("");
                          }}
                        >
                          <input
                            type="text"
                            value={groupName}
                            placeholder="Add New Group"
                            onChange={(e) => setGroupName(e.target.value)}
                            onBlur={() => {
                              groupName && addUserGroup();
                              setVal(false);
                              setGroupName("");
                            }}
                          />
                        </form>
                      </>
                    )}
                  </Grid>
                  <Grid item md={10} className="user-list-right "  >
                    {/* table */}
                    <Table id="usertable" data-order='[[ 0, "desc" ]]'>
                      <div className=" overflow-scroll" >
                        <TableHead className="sticky-table-header">
                          <TableRow>
                            <TableCell className="d-none">ID</TableCell>
                            <TableCell style={{ width: "20%" }}>User Name</TableCell>
                            <TableCell style={{ width: "20%" }}>Vehicle Group</TableCell>
                            <TableCell style={{ width: "20%" }}>User Role</TableCell>
                            {/* <TableCell>Mobile Number</TableCell> */}
                            <TableCell style={{ width: "20%" }}>Email ID</TableCell>
                            <TableCell style={{ width: "0%" }}>Status</TableCell>
                            <TableCell style={{ width: "20%" }}>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!userManagement ||
                            userManagement?.length == 0 ||
                            (noSearchResultData && searchResult?.length == 0) ? (
                            <TableRow>
                              {/* <TableRow style={{position:'absolute',top:'50%',left:'50%'}}> */}
                              {/* <TableCell align="center"> */}
                              No Data Found{" "}
                              {/* </TableCell> */}
                            </TableRow>
                          ) : (
                            ""
                          )}
                          {!noSearchResultData &&
                            searchResult?.length == 0 &&
                            (rowsPerPage > 0
                              ? userManagement?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              : userManagement
                            ).map((user, i) => (
                              // userManagement.map((user, i) => (
                              <TableRow key={i}>
                                <TableCell className="text-center d-none">
                                  {user.id}
                                </TableCell>
                                <TableCell>
                                  <img
                                    className="user_img vertical-align-middle w-40"
                                    src={
                                      user.user_photo == imgUrl ||
                                        user.user_photo == null
                                        ? User_Icon
                                        : user.user_photo
                                    }
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = User_Icon;
                                    }}

                                  />{" "}
                                  <span className="vertical-align-middle">
                                    {user.user_name}
                                  </span>
                                </TableCell>
                                {/* <TableCell>{user.mobile_number }</TableCell> */}
                                <TableCell>{user.vehicle_group ? user.vehicle_group.split(',').map((item) => (<>{item},<br /></>)) : ''}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell className="text-center">
                                  <span className={user.status == 'Active'
                                    || user.status == 'active' ? "active-ta font-12" :
                                    "inactive-ta font-12"}>
                                    {user.status.toUpperCase()}
                                  </span>
                                </TableCell>
                                <TableCell className="text-center"
                                >
                                  <p className="accidentcell textcentercell" style={{ marginTop: "10px", marginBottom: "10px", display: "flex", justifyContent: "center" }}>
                                    <span
                                      className="eye-icon"
                                      onClick={() => {
                                        // navigate(
                                        //   "/user_management/view_user/" + user.id
                                        // )
                                        setUserViewType('View')
                                        setSelectedUserDetails(user.id);
                                        setopenUserPopup(true)
                                      }}
                                    >
                                      <img className="table-icons" src={Eye} />
                                    </span>
                                    <span
                                      className="table-icons pointer"
                                      onClick={() => {
                                        // navigate(
                                        //   "/user_management/edit_user/" + user.id
                                        // )
                                        setUserViewType('Edit')
                                        setSelectedUserDetails(user.id);
                                        setopenUserPopup(true)
                                      }}
                                    >
                                      <img className="table-icons"
                                        src={Pencil}
                                      />
                                    </span>
                                    {/* <img className='table-icons pointer' src={Trash} onClick={() => handleDeleteOpen(user.id)} /> */}
                                    <span className="table-icons">
                                      <Switch
                                        checked={
                                          user.status.toLowerCase() === "active"
                                            ? true
                                            : false
                                        }

                                        onChange={() =>
                                          handleClickOpen(user)
                                        }
                                      />
                                      <Box>
                                      </Box>
                                    </span>
                                  </p>
                                </TableCell>

                              </TableRow>

                            ))}
                          <Dialog
                            open={openAlert}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {"Are You Sure ! You Want to update Status ?"}

                            </DialogTitle>
                            <DialogContent>
                              {/* <DialogContentText id="alert-dialog-description">
          {"Are You Sure  You Want to update Status"}
          </DialogContentText> */}
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={() => handleSubmitSearch()}>Confirm</Button>
                              <Button onClick={handleClose} autoFocus>
                                Back
                              </Button>
                            </DialogActions>
                          </Dialog>

                          {searchResult?.length != 0 &&
                            searchResult?.map((user, i) => (
                              <TableRow key={i}>
                                <TableCell className="text-center d-none">
                                  {user.id}
                                </TableCell>
                                <TableCell>
                                  <img
                                    className="user_img vertical-align-middle w-40"
                                    src={
                                      user.user_photo == imgUrl
                                        ? User_Icon
                                        : user.user_photo
                                    }
                                  />{" "}
                                  <span className="vertical-align-middle">
                                    {user.user_name}
                                  </span>
                                </TableCell>
                                {/* <TableCell>{user.mobile_number}</TableCell> */}
                                <TableCell>{user.vehicle_group ? user.vehicle_group.split(',').map((item) => (<>{item},<br /></>)) : ''}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell className="text-center">
                                  <span
                                    className={
                                      user.status == "active" ||
                                        user.status == "Active"
                                        ? "active-ta font-12"
                                        : "inactive-ta font-12"
                                    }
                                  >
                                    {user.status.toUpperCase()}
                                  </span>
                                </TableCell>
                                <TableCell className="text-center">
                                  <p className="justify">
                                    <span
                                      className="eye-icon"
                                      onClick={() =>{
                                        // navigate(
                                        //   "/user_management/view_user/" + user.id
                                        // )
                                        setUserViewType('View');
                                        setSelectedUserDetails(user.id);
                                        setopenUserPopup(true)
                                      }}
                                    >
                                      <img className="table-icons" src={Eye} />
                                    </span>
                                    <span
                                      className="table-icons pointer"
                                      onClick={() =>{
                                        // navigate(
                                        //   "/user_management/edit_user/" + user.id
                                        // )
                                        setUserViewType('Edit');
                                        setSelectedUserDetails(user.id);
                                        setopenUserPopup(true)
                                      }}
                                    >
                                      <img className="table-icons" src={Pencil} />
                                    </span>
                                    <img
                                      className="table-icons pointer"
                                      src={Trash}
                                      onClick={() => handleDeleteOpen(user.id)}
                                    />
                                  </p>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </div>
                      <tfoot>
                        <tr>
                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: -1 },
                            ]}
                            colSpan={6}
                            count={userManagement.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            slotProps={{
                              select: {
                                "aria-label": "rows per page",
                              },
                              actions: {
                                showFirstButton: true,
                                showLastButton: true,
                              },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </tr>
                      </tfoot>
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      {openUserPopUp &&
        <AddUserManagement
          openUserPopUp={openUserPopUp}
          onCloseUserPopup={onCloseUserPopup}
          user_id={selectedUserDetails}
          userViewType={userViewType}
          getuserManagement ={ getuserManagement}
          userManagement={userManagement}
        />
      }

      <Dialog
        open={deleteOpen}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure You Want to delete this record?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteConfirm}
            color="primary"
            variant="contained"
            className="whitebg"
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            className="whitebg"
            onClick={handleDeleteClose}
            color="primary"
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default User_Management;
