import React, { useState, useEffect } from "react";
import MainLogo from "../../Assets/images/logo.png";
import Search_icon from "../../Assets/images/search-icon.png";
import Notification_icon from "../../Assets/images/bell.png";
import User_icon from "../../Assets/images/user.png";
import profileimg from "../Header/profileimg.png"
import Not_img from "../../Assets/images/dashborad1.png";
import User_Icon from "../../Assets/images/user-icon.png";
import Responsive_menu from "../../Assets/images/responsive-menu.png";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { Card } from '@mui/material'
import MuiDialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
// import CloseIcon from '@mui/icons-material/Close'
import "../../Assets/css/style.css"
import { styled, Box } from '@mui/system'
import {
    Grid,
    Table,
    TableHead,
    TableCell,
    TableBody,
    Icon,
    TableRow,
    Button,
    Tooltip,
    IconButton,
} from '@mui/material'
import { NavLink, HashRouter as Router, useLocation, Link } from 'react-router-dom';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import { Menu, ThemeProvider } from '@mui/material'
import drop_arrow from "../../Assets/images/drop-arrow.png";
import { getDataFromApi, postDataFromApi } from "../../Services/CommonServices"
import { userManagementGet } from "../../Services/api"
import ChangePassword from "../login/change_password";
import { Nav } from "react-bootstrap";

const MenuButton = styled(Box)(({ theme }) => ({
    display: 'inline-block',

}))
$(document).ready(function () {
    $(document).on("click", ".responsive-menu", function () {
        if ($(".header-bottom").css("display") == "none") {
            $(".header-bottom").slideDown(500);
        } else {
            $(".header-bottom").slideUp(500);
        }
    });
    $(document).on("click", ".table-drop-icon", function () {
        $(this).next().toggleClass("active");
        // $(this).closest('.parent').find('a').toggleClass('active');

    });
    $(document).on("click", "body", function (e) {
        if ($(e.target).closest(".table-drop-icons").length > 0) {
            return false;
        } else {
            $(".table-dropdown.active").removeClass("active");
        }
    });
    $(".menu-hover").hover(function () {
        $("body").addClass("add-overlay");
    }, function () {
        $("body").removeClass("add-overlay");
    });
    $(document).on("click", ".toggle-custom-drop", function () {
        $(".notification-drop").toggle();
        $("body").toggleClass("add-overlay");
    });
    $(document).on("click", ".inc-size", function () {
        $(this).toggleClass("active");
        $(".inc-size .event-popup").toggle();
    })
    $(document).on("click", "body", function (e) {
        if ($(e.target).closest(".inc-size").length > 0) {
            return false;
        } else {
            $(".inc-size.active").removeClass("active");
            $(".inc-size .event-popup").css("display", "none");
        }
    });
})

const Main_header = ({ isOpen }) => {
    const navigate = useNavigate();
    // const Urls =["/reports/report_by_vehicle","/reports/report_by_driver","/reports/report_by_user"]


    const { pathname } = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [singleUser, setsingleUser] = React.useState([])
    const [open, setOpen] = useState(false)
    const userCompany = localStorage.getItem('userCompany')
    var userRole = localStorage.getItem('userRole')
    if (userRole) {
        userRole = userRole.toLowerCase();
    }

    const chkRole = ['company user', 'company admin', 'company manager']
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    let horizontalPosition = 'left'
    const handleClose = () => {
        setAnchorEl(null)
    }
    const gesingleUser = async () => {
        if (localStorage.getItem('currentUser')) {
            var response = ''
            var id = localStorage.getItem('currentUser')
            response = await getDataFromApi(userManagementGet + id + '/')
            if (response && response.status == 200 && response.data != null) {
                setsingleUser(response.data)
                localStorage.setItem('currentUsername', response.data.user_name)

            }

        } else {
            setsingleUser([])
        }


    }
    // const authVerify = ()=>{
    //     const authurls = ['/', '/login', '/forgot-password']
    //         if (authurls.includes(pathname)) {
    //             if (localStorage.getItem("accessToken") != null) {
    //                 navigate('/dashboard')
    //             }
    //         } else {
    //             if
    //                 (localStorage.getItem("accessToken") == null ||localStorage.getItem('accessToken') == undefined ||
    //                 localStorage.getItem('accessToken') == '') {
    //                 navigate('/login')
    //             }
    //         }

    // }



    const StyledCard = styled(Card)(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',

        background: '#0E0E23',

    }))
    const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
        margin: 0,
        padding: theme.spacing(2),
        '& .closeButton': {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }))
    const DialogTitle = ((props) => {
        const { children, onClose } = props
        return (
            <DialogTitleRoot disableTypography>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="Close"
                        className='closeButton'
                        onClick={onClose}
                    >
                        {/* <CloseIcon /> */}
                    </IconButton>
                ) : null}
            </DialogTitleRoot>
        )
    })

    const handleClickLogout = async () => {
        localStorage.clear();
        navigate('/login')
    }




    useEffect(() => {
        // authVerify()
        gesingleUser()

    }, []);
const classNames=isOpen ?"header-main sliderOpen": "header-main sliderClose"
    return (
        <>
            <div className={classNames}>
                <div className="header-top" style={{display:'flex',justifyContent:'space-between',allignItems:'center'}}>
                    <div className="header-logo resp-text-left">
                        {/* <NavLink to="/events/livestream"> */}
                        {(singleUser.role==='Company User' || singleUser.role==='Monitor Company Admin'|| singleUser.role==='Monitor Company User')
                        ?( <Nav><img src={MainLogo}  alt='' /></Nav>) 
                       :( <NavLink to={"/dashboard"}><img src={MainLogo}  alt='' /></NavLink>)}
                        <img className="responsive-menu" alt='' src={Responsive_menu} />
                    </div>                  
                    <div className="header-not" >
                        <div className="user-sec" style={{display:'flex' ,alignItems:'center',justifyContent:'flex-end'}}>
                            <div className="user-profile">
                                <img 
                                alt=''
                                className ="userimg"
                                // src={singleUser && singleUser?.user_photo ? singleUser?.user_photo : profileimg}
                                src={singleUser && singleUser?.user_photo == null ? profileimg:singleUser?.user_photo }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = profileimg;
                                  }}
                                />
                            </div>
                            <div className="user-detail" style={{ top: "8px",paddingRight:'0px' }}>
                                <h4 className="user-name">{singleUser && singleUser.user_name ? singleUser.user_name : 'JOHN DOE'}</h4>
                                <p className="user-pos">{singleUser && singleUser.role ? singleUser.role : 'Sr. ENT Doctor'}</p>
                                <MenuButton onClick={handleClick} className={anchorEl != null ? "open menubuttonicon pointer" : "menubuttonicon pointer"}>
                                    <img src={drop_arrow} alt='' />
                                </MenuButton>

                                <Menu
                                    elevation={8}
                                    getContentAnchorEl={null}
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: horizontalPosition,
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: horizontalPosition,
                                    }}
                                    className="pad-0 user-drop"
                                >
                                    <div
                                        onClick={() => setOpen(true)}
                                        key="1"
                                    >
                                        Reset Password
                                    </div>

                                    <div
                                        onClick={handleClickLogout}
                                        key="2"
                                    >
                                        Logout
                                    </div>

                                </Menu>

                            </div>
                        </div>
                    </div>
                </div>
                {open && <ChangePassword setOpen={setOpen} />}
            </div>
        </>
    )
}

export default Main_header;