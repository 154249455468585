import httpService from "./axiosIntance";
export const filterEvents = (data,isPaginate="") => {
    if(isPaginate){
        return httpService.post("event/event_filter_with_search_pag/", data)
    }
    return httpService.post("event/event_filter_with_search/", data)
}
export const filterEventswithEventIds = (data) => {
    return httpService.post("event/event_seq_id_search_entered/", data)
}
export const filterEventFieldApi = () => {
    const userId = localStorage.getItem("currentUser");
    return httpService.get("event/event_filters/"+userId+'/')
}

export const getEventDataApi = (data) => {
    return httpService.post("event/priority_events/", data)
}
export const getEventAccData = () => {
    const userId = localStorage.getItem("currentUser");

    return httpService.get("event/accidentevents/"+userId+'/')
}
export const handleAccidentApi= (data,id) => {
    return httpService.put(`event/eventdetails/${id}/`,data)
}
export const getEventVideoData = () => {
    const userId = localStorage.getItem("currentUser");

    return httpService.get("event/video_event_filter/"+userId+'/')
}
export const getEventManualData = () => {
    const userId = localStorage.getItem("currentUser");

    return httpService.get("event/manual_event_get_api/")
}

export const getAllEvenetDataAPI = () => {
    const userId = localStorage.getItem("currentUser");

    return httpService.get("event/user_role_based_events/"+userId+'/')
}
