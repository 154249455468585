import { configureStore } from '@reduxjs/toolkit'
import { EventFilterFieldsSlice } from './Events/events'
import { filterFieldsSlice } from './Reports/FilterSlice'


export default configureStore({
    reducer: {
        ReportField: filterFieldsSlice.reducer,
        Event: EventFilterFieldsSlice.reducer
    },
})