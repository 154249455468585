import { country_arr } from './countries';
export const gender_opt = [
    { id: "MA", label: "MALE" },
    { id: "FM", label: "FEMALE" },
    { id: "OT", label: "OTHERS" },
]
export const driver_opt = [
    { id: "MA", label: "MALE" },
    { id: "FM", label: "FEMALE" },
    { id: "OT", label: "OTHERS" },
]

    var ctarray = []
    country_arr.map((opt)=>{
        var ct=[]
        ct['id'] = opt
        ct['label'] = opt
        ctarray.push(ct)
    })
    export const countryOpt = ctarray


export const country_opt = [
    { id: "IN", label: "India" },
    { id: "AF", label: "Afghanistan" },
    { id: "AS", label: "Australia" },
    { id: "AG", label: "Argentina" },
    { id: "AR", label: "Armania" },
    { id: "AN", label: "Angola" },
    { id: "BZ", label: "Brazil" },
    { id: "BH", label: "Bhutan" },
    { id: "BN", label: "Bangladesh" },
    { id: "CH", label: "China" },
    { id: "PK", label: "Pakistan" },
    { id: "CA", label: "Canada" },
    { id: "US", label: "USA" },
    { id: "UE", label: "UAE" },
    { id: "IR", label: "Iran" },
    { id: "IQ", label: "Iraq" },
    { id: "SD", label: "Saudi Arabia" },
    { id: "ID", label: "Indonesia" },
    { id: "EN", label: "England" },
    { id: "GE", label: "Germany" },
    { id: "IT", label: "Italy" },
    { id: "FR", label: "France" },
    { id: "PD", label: "Poland" },
    { id: "UR", label: "Ukraine" },
    { id: "RO", label: "Romania" },
    { id: "MX", label: "Mexico" },
    { id: "RU", label: "Russia" },
    { id: "SW", label: "Sweden" },
    { id: "NO", label: "Norway" },
    { id: "BL", label: "Belaras" },
    { id: "SL", label: "Sri Lanka" },
    { id: "JP", label: "Japan" },
    { id: "NK", label: "North Korea" },
    { id: "SK", label: "South Korea" },
    { id: "SC", label: "Scotland" },
    { id: "ND", label: "Nedherland" },
    { id: "NP", label: "Nepal" },
    { id: "SP", label: "Spain" },
    { id: "PO", label: "Portugal" },
    { id: "SA", label: "South Africa" },
    { id: "NZ", label: "New Zealand" },
    { id: "IR", label: "Irealand" },
    { id: "TH", label: "Thailand" },
    { id: "MY", label: "Maynmar" },
    { id: "EG", label: "Egypt" },
    { id: "AL", label: "Algeria" },
    { id: "ML", label: "Malasiya" },
    { id: "SN", label: "Singapore" },
    { id: "CO", label: "Colombia" },
    { id: "BO", label: "Bolivia" },
    { id: "PR", label: "Peru" },
    { id: "CH", label: "Chili" },
    { id: "PA", label: "Paraguay" },
    { id: "VN", label: "Venezuela" },
    { id: "DN", label: "Denmark" },
    { id: "ZR", label: "Zeck Republic" },
]
export const state_opt = [
    { id: 'AL', label: 'Alabama'},
    { id: 'AK', label: 'Alaska'},
    { id: 'AZ', label: 'Arizona'},
    { id: 'AR', label: 'Arkansas'},
    { id: 'CA', label: 'California'},
    { id: 'CO', label: 'Colorado'},
    { id: 'CT', label: 'Connecticut'},
    { id: 'DE', label: 'Delaware'},
    { id: 'DC', label: 'District Of Columbia'},
    { id: 'FL', label: 'Florida'},
    { id: 'GA', label: 'Georgia'},
    { id: 'HI', label: 'Hawaii'},
    { id: 'ID', label: 'Idaho'},
    { id: 'IL', label: 'Illinois'},
    { id: 'IN', label: 'Indiana'},
    { id: 'IA', label: 'Iowa'},
    { id: 'KS', label: 'Kansas'},
    { id: 'KY', label: 'Kentucky'},
    { id: 'LA', label:  'Louisiana'},
    { id: 'ME', label:  'Maine'},
    { id: 'MD', label:  'Maryland'},
    { id: 'MA', label:  'Massachusetts'},
    { id: 'MI', label:  'Michigan'},
    { id: 'MN', label:  'Minnesota'},
    { id: 'MS', label:  'Mississippi'},
    { id: 'MO', label:  'Missouri'},
    { id: 'MT', label:  'Montana'},
    { id: 'NE', label:  'Nebraska'},
    { id: 'NV', label:  'Nevada'},
    { id: 'NH', label:  'New Hampshire'},
    { id: 'NJ', label:  'New Jersey'},
    { id: 'NM', label:  'New Mexico'},
    { id: 'NY', label:  'New York'},
    { id: 'NC', label:  'North Carolina'},
    { id: 'ND', label:  'North Dakota'},
    { id: 'OH', label:  'Ohio'},
    { id: 'OK', label:  'Oklahoma'},
    { id: 'OR', label:  'Oregon'},
    { id: 'PA', label:  'Pennsylvania'},
    { id: 'RI', label:  'Rhode Island'},
    { id: 'SC', label:  'South Carolina'},
    { id: 'SD', label:  'South Dakota'},
    { id: 'TN', label:  'Tennessee'},
    { id: 'TX', label:  'Texas'},
    { id: 'UT', label:  'Utah'},
    { id: 'VT', label:  'Vermont'},
    { id: 'VA', label:  'Virginia'},
    { id: 'WA', label:  'Washington'},
    { id: 'WV', label:  'West Virginia'},
    { id: 'WI', label:  'Wisconsin'},
    { id: 'WY', label:  'Wyoming'},
]
export const manage_opt = [
    { id: "Manage 1", label: "Manage 1" },
    { id: "Manage 2", label: "Manage 2" },
    { id: "Manage 3", label: "Manage 3" },
]