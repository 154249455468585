import React, { useState, useEffect } from "react";
import { useTheme, styled } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseBtn from "../../Assets/images/close.png";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Box,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Icon,
  TableRow,
  Button,
  Tooltip,
} from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import User_Icon from "../../Assets/images/user-icon.png";
import Close_btn from "../../Assets/images/close-btn-list.png";
import Grey_circle from "../../Assets/images/grey-circle.png";
import {
  userManagementList,
  userManagementGet,
  userManagementAdd,
  userManagementUpdate,
  userGroup,
  userPicUpdate,
  companyManagementList,
  vehicalGroupList,
} from "../../Services/api";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
  imgUrl,
} from "../../Services/CommonServices";
import { useParams } from "react-router-dom";
import AlertMessage from "../commoncomponent/AlertMessage";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from "moment";
import {
  gender_opt,
  country_opt,
  state_opt,
  countryOpt
} from "../commoncomponent/CommonObject";
import { populateStates, populateCountries, country_arr, s_a } from "../commoncomponent/countries"

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const AutoComplete = styled(Autocomplete)(() => ({
  width: 300,
  marginBottom: "16px",
}));

const permission_opt = [
  { id: 1, label: "Add" },
  { id: 2, label: "Action Workflow 1" },
  { id: 3, label: "Action Workflow 2" },
];

const status_opt = [
  { id: "Active", label: "Active" },
  { id: "Inactive", label: "Inactive" },
];

// const user_role_opt = [
//   { id: "iCAM Admin", label: "iCAM Admin" },
//   { id: "iCAM User", label: "iCAM User" },
//   { id: "Company Admin", label: "Company Admin" },
//   { id: "Company User", label: "Company User" },
//   { id: "Company Manager", label: "Company Manager" },
// ];

const AddUserManagement = ({ openUserPopUp, onCloseUserPopup, user_id, userViewType, getuserManagement, userManagement }) => {
  const disabled = userViewType === 'View' ? true : false
  // let { user_id } = useParams();
  const navigate = useNavigate();
  const [alert, setalert] = useState(false);
  const [alertMessage, setalertMessage] = useState("");
  const [alertType, setalertType] = useState("");
  const [usergroup, setusergroup] = useState([]);
  const [stateOpt, setstateOpt] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([])
  const [vehicleOptions, setvehicleOptions] = useState([])
  const [companyOptionsDuplicate, setCompanyOptionsDup] = useState([])
  const [vehicleOptionsDuplicate, setvehicleOptionsDuplicate] = useState([])
  const [subCompanyOptions, setSubcompanyOptions] = useState([])
  const [subcompanyOptionsDuplicate, setSubcompanyOptionsDuplicate] = useState([])
  const [isViewEnable, setIsViewEnable] = useState(true)
  const [vehicleGroupOpt, setvehicleGroupOpt] = useState([]);
  const [selected, setSelected] = useState("yes");
  const [singleUser, setsingleUser] = useState([]);
  const [error, setError] = useState('')
  const [is_edit_loaded, set_is_edit_loaded] = useState(false);
  const [isVehicleLoading, setIsVehicleLoading] = useState(false);
  const [isEventActionLoading, setIsEventActionLoading] = useState(false);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);
  const [fileObj, setObj] = useState(null);
  const [dateopen, setdateOpen] = useState(false);
  const [samePage, setsamePage] = useState(false);
  const [previewImg, setpreviewImg] = useState("");
  const userCompany = localStorage.getItem('userCompany')
  const userRole = localStorage.getItem('userRole')
  var user_role_opt = []
  if (userRole.toLowerCase() == "icam admin") {
    user_role_opt = [
      { id: "iCAM Admin", label: "iCAM Admin" },
      { id: "iCAM User", label: "iCAM User" },
      { id: "Company Admin", label: "Company Admin" },
      { id: "Company User", label: "Company User" },
      { id: "Company Manager", label: "Company Manager" },
      { id: "Monitor Company User", label: "Monitor Company User" },
      { id: "Monitor Company Admin", label: "Monitor Company Admin" },
    ];
  }
  else if (userRole.toLowerCase() == "icam user") {
    user_role_opt = [
      { id: "Company Admin", label: "Company Admin" },
      { id: "Company User", label: "Company User" },
      { id: "Company Manager", label: "Company Manager" },
      { id: "Monitor Company User", label: "Monitor Company User" },
      { id: "Monitor Company Admin", label: "Monitor Company Admin" },
    ];
  }
  else if (userRole.toLowerCase() == "company admin") {
    user_role_opt = [
      { id: "Company User", label: "Company User" },
      { id: "Company Manager", label: "Company Manager" },
    ];
  } else if (userRole.toLowerCase() == "Monitor Company Admin") {
    user_role_opt = [
      { id: "Monitor Company User", label: "Monitor Company User" }
    ];
  }

  function confirm() {
    getuserManagement()
    onCloseUserPopup()
    setalert(false);
    navigate("/user_management/");
  }

  function confirm() {
    getuserManagement()
    onCloseUserPopup()
    setalert(false);
    if (!samePage) {
      navigate("/user_management/");
    } else {
      setsamePage(false);
    }
  }
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const handleSubmitSearch = async (event) => {
    var formData = new FormData();
    formData.append("user_id", formdata.user_id);
    formData.append("mobile_number", formdata.mobile_number ? formdata.mobile_number : 0);
    formData.append("user_name", formdata.user_name);
    formData.append("dob", formattedDate);
    formData.append("email", formdata.email);
    formData.append("gender", formdata.gender);
    formData.append("country", formdata.country);
    formData.append("state", formdata.state);
    formData.append("city", formdata.city);
    formData.append("zip_code", formdata.zip_code ? formdata.zip_code : 0);
    formData.append("address", formdata.address);
    formData.append("status", formdata.status);
    formData.append("role", formdata.role);
    formData.append("user_monitor_company", formdata.subCompany_name);
    formData.append("user_group", formdata.user_group.join(','));
    //   if (formdata.role === "Company User") {
    //     formData.append("user_group", null);
    // } else {
    //     formData.append("user_group", formdata.user_group.join(','))
    // }
    formData.append("vehicle_group", formdata.vehicle_group.join(','));
    formData.append('company_name', formdata.company_name)
    // if (fileObj) { formData.append("user_photo", fileObj) }
    fileObj === null ? formData.append("user_photo", null) : formData.append("user_photo", formdata.logo);
    formData.append('driver_group', '')
    // if (!user_id) {
    //   // formData.append(
    //   //   "user_photo",
    //   //   formdata.user_photo && formdata.user_photo.size
    //   //     ? formdata.user_photo
    //   //     : null
    //   // );
    //   fileObj === null ? formData.append("user_photo", '') : formData.append("user_photo", formdata.logo)
    // }
    // else {
    //   // formData.append('user_photo', formdata.image_name)
    //   fileObj === null ? formData.append("user_photo", '') : formData.append("user_photo", formdata.logo);
    // }
    var userGroup = "";
    if (formdata.user_group) {
      usergroup.map((opt) => {
        if (opt.id == formdata.user_group) {
          userGroup = { id: opt.id, label: opt.name };
        }
      });
    }
    var newformdata = formData
    var response = "";
    if (user_id) {
      response = await putDataFromApi(
        userManagementUpdate + user_id + "/",
        newformdata
      );
    } else {
      response = await postDataFromApi(userManagementAdd, newformdata);
    }
    if (user_id) {
      if (response && response.status == 200) {
        setalertMessage("User Updated successfully");
        setalert(true);
        setalertType("success");
      } else {
        setalertMessage("error...");
        setalert(true);
        setalertType("error");
      }
    } else {
      if (response && response.status == 200) {
        setalertMessage("User Added successfully");
        setalert(true);
        setalertType("success");
      } else {
        setalertMessage("error...");
        setalert(true);
        setalertType("error");
      }
    }
  };
  const [formdata, setFormData] = useState({
    user_id: "",
    user_name: "",
    mobile_number: "",
    dob: null,
    email: "",
    user_photo: "",
    gender: "",
    country: "",
    vehicle_group: [],
    user_group: [],
    state: "",
    city: "",
    zip_code: "",
    address: "",
    status: "Active",
    role: "",
    user_grouping: [],
    company_name: "",
    subCompany_name: null,
    image_name: "",
    logo: ''
  });

  useEffect(() => {
    if (user_id !== '') {
      getsingleUser();
    } else {
      getvehicleGroup()
      getusergroup();
      getCompanies()
      set_is_edit_loaded(true);
    }
  }, [user_id]);

  useEffect(() => {
    if (formdata?.role) {
      var companyManagement = [...companyOptions]
      if ((formdata?.role === "Company Manager" || formdata?.role === 'Company User' || formdata?.role === 'Company Admin')) {
        const filterCompanies = companyManagement?.filter(companyName => companyName?.monitor_company_check === false);
        setCompanyOptionsDup(filterCompanies);
      } else if ((formdata?.role === 'Monitor Company User' || formdata?.role === 'Monitor Company Admin')) {
        const filterCompanies = companyManagement?.filter(companyName => companyName?.monitor_company_check === true)
        setCompanyOptionsDup(filterCompanies);
      }
      else {
        setCompanyOptionsDup(companyOptions);
      }
    }
    var vehcilegroupOptions = [...vehicleOptions]
    var selectedcompanies = getSelectedItem(
      formdata.company_name,
      companyOptionsDuplicate
    )
    if ((formdata.role === "Company Manager" || formdata.role === 'Company User' || formdata.role === 'Company Admin' )) {
      var vehicleSplit = selectedcompanies?.companyVehicle ? selectedcompanies?.companyVehicle.split(',') : []
      const filterVehicleGroups = vehcilegroupOptions.filter((vehiclegroup) => {
        return vehiclegroup?.vehicle_group && vehiclegroup?.vehicle_group?.split(',').some(r => vehicleSplit.indexOf(r) > -1)
      })
      setvehicleOptionsDuplicate(filterVehicleGroups)
      // setvehicleOptionsDuplicate(filterVehicleGroups.length === 0 ? [{
      //   "id": formdata.vehicle_group,
      //   "label": formdata.vehicle_group,
      //   // "vehicle_group": "LIEBEN_LONGHAUL,LIEBEN_LONGHAUL_3-CAM,LIEBEN_LOGISTICS"
      // }] : filterVehicleGroups)
    }
    else if ((formdata.role === 'Monitor Company User' || formdata.role === 'Monitor Company Admin')) {
      var companysplit = selectedcompanies?.monitor_companys ? selectedcompanies?.monitor_companys : {}
      if (typeof companysplit === 'string') {
        try {
          companysplit = JSON.parse(companysplit)
        } catch (error) {
          console.error('Error parsing JSON:', error)
        }
      }
      var companyopt = [];
      Object.keys(companysplit).forEach((cmp) => {
        companyopt.push({
          id: cmp,
          label: cmp
        });
      });
      setSubcompanyOptionsDuplicate(companyopt)
    }
  }, [companyOptions, vehicleOptions, usergroup,formdata.company_name,formdata.role,isCompanyLoading])
  useEffect(() => {
    setIsVehicleLoading(false);
    setTimeout(() => {
      setIsVehicleLoading(true);
    }, 10);
  }, [formdata.company_name, companyOptions, vehicleOptions, usergroup,isCompanyLoading])

  const getusergroup = async () => {
    var response = "";
    response = await getDataFromApi(userGroup);
    if (response && response.status == 200 && response.data != null) {
      // setusergroup(response.data);
      var companyopt = [];
      response.data.map((company, i) => {
        companyopt.push({
          'id': company.id,
          'label': company.name,
        })
      })
      setusergroup(companyopt)
    }
    setTimeout(() => {
      setIsEventActionLoading(true);
    }, 100);
  };
  const getCompanies = async () => {
    var query = ""
    const response = await getDataFromApi(companyManagementList);
    if (response && response.status == 200 && response.data != null) {
      var companyopt = [];
      var vehiclopt = []
      var monitorCompany = []
      response.data.map((company, i) => {
        companyopt.push({
          'id': company.company_name,
          'label': company.company_name,
          'monitor_company_check': company.monitor_company_check,
          'companyVehicle': company.vehicle_group,
          'monitor_companys': company.monitor_companys,
        });
      })
      response.data.map((vehicle, i) => {
        vehiclopt.push({
          'id': vehicle.company_name,
          'label': vehicle.company_name,
          'vehicle_group': vehicle.vehicle_group
        });
      })
      response.data.map((monitor, i) => {
        monitorCompany.push({
          'id': monitor.company_name,
          'label': monitor.company_name,
          'monitor_company': monitor.monitor_company
        });
      })
      if ((formdata.role === "Company Manager" || formdata.role === 'Company User' || formdata.role === 'Company Admin')) {
        const filterCompanies = companyopt.filter(com => com.monitor_company_check === false);
        setCompanyOptionsDup(filterCompanies);

        const filterVehicleGroups = vehiclopt.filter(vehiclegroup => vehiclegroup.vehicle_group)
        setvehicleOptionsDuplicate(filterVehicleGroups)
      } else {
        const filterCompanies = companyopt.filter(com => com.monitor_company_check === true)
        setCompanyOptionsDup(filterCompanies);
        // const filterSubCompanies = monitorCompany.filter(company => company?.monitor_company)
        // setSubcompanyOptionsDuplicate(filterSubCompanies)

      }
      if (userRole.toLowerCase() === 'company user' || userRole.toLowerCase() === 'company admin') {
        setCompanyOptions([{ label: userCompany, id: userCompany }])
        setFormData((formData) => ({
          ...formData,
          ['company_name']: userCompany,
        }));
      }
      else {
        setCompanyOptions(companyopt)
        setvehicleOptions(vehiclopt)
        setSubcompanyOptions(monitorCompany)
      }
    }
    setTimeout(() => {
      setIsCompanyLoading(true);
    }, 100);
  };
  const getsingleUser = async () => {
    var response = "";
    response = await getDataFromApi(userManagementGet + user_id + "/");
    if (response && response.status == 200 && response.data != null) {
      setsingleUser(response.data);
      if (user_id) {
        var userdata = response.data;
        // getstateOpt(userdata.country)
        // var image_name = "";
        // if (userdata.user_photo) {
        //   var user_photo = userdata.user_photo.split('?')[0];
        //   image_name = user_photo.split('media/').slice(-1).pop()
        // }
        setFormData((formData) => ({
          ...formData,
          ["user_id"]: userdata.user_id,
          ["user_name"]: userdata.user_name,
          ["mobile_number"]: userdata.mobile_number ? userdata.mobile_number : '',
          ["dob"]: userdata.dob,
          ["email"]: userdata.email,
          ["logo"]: userdata.user_photo,
          ["gender"]: userdata.gender,
          ["country"]: userdata.country,
          ["state"]: userdata.state,
          ["city"]: userdata.city,
          ["zip_code"]: userdata.zip_code ? userdata.zip_code : '',
          ["address"]: userdata.address,
          ["status"]: userdata.status,
          ["role"]: userdata.role,
          ["subCompany_name"]: userdata.user_monitor_company,
          // ["user_grouping"]: userdata.user_grouping.id,
          ["user_group"]: userdata.user_group !== null ? userdata.user_group.split(',') : [],
          ["vehicle_group"]: userdata.vehicle_group !== null ? userdata.vehicle_group.split(',') : [],
          ['company_name']: userdata.company_name,
          // ['image_name']: image_name,
        }));
        // getCompanies(userdata)

        setTimeout(() => {
          getvehicleGroup()
          getCompanies()
          getusergroup();
          set_is_edit_loaded(true);
        }, 1);
      }
    }
  };

  const getvehicleGroup = async () => {
    var response = "";
    response = await getDataFromApi(vehicalGroupList);
    if (response && response.status == 200 && response.data != null) {
      var vehiclearray = [];
      response.data.map((opt) => {
        var vh = [];
        vh["id"] = opt.name;
        vh["label"] = opt.name;
        vehiclearray.push(vh);
      });
      setvehicleGroupOpt(vehiclearray);
    }
    setIsVehicleLoading(false);
    setTimeout(() => {
      setIsVehicleLoading(true);
    }, 100);
  };
  const checkEmailExists = (enteredEmail) => {
    for (const user of userManagement) {
      if (user?.email === enteredEmail) {
        return true;
      }
    }
    return false;
  };

  const emailValueChanges = (e) => {
    const enteredEmail = e.target.value;
    setFormData({ ...formdata, email: enteredEmail });
    if (checkEmailExists(enteredEmail)) {
      setError("Email already exists !");
    } else {
      setError("");
    }
  };

  function formdataValueChange(e) {
    var value = e.target.value.trimStart();
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: value,
    }));
  }
  const onFileChange = async (event) => {
    var file = event.target.files[0];
    setObj(file);
    setFormData((formData) => ({
      ...formData,
      [event.target.name]: file,
    }));
    setpreviewImg(URL.createObjectURL(file));
  };
  function changeDropdownValue(type, e) {
    setFormData((formData) => ({
      ...formData,
      subCompany_name: null
    }));

    if (e) {
      var value = e.id;
    } else {
      var value = "";
    }
    if (type == 'vehicle_group' || type == 'driver_group' || type == 'user_group') {
      var values = []
      e.map((prop, i) => {
        var data = prop.label
        values.push(data)
      })
      var value = values
    }
    setFormData((formData) => ({
      ...formData,
      [type]: value,
    }));

    if (type == "role") {
      if (value === 'iCAM Admin' || value === 'iCAM User') {
        // user_role_opt.push({ id: "iCAM Company", label: "iCAM Company" })
        changeDropdownValue("company_name",
          { id: "iCAM Company", label: "iCAM Company", vehicle_group: null, monitor_companys: null })
      }
      else {
        var companyManagement = [...companyOptions]
        if ((value === "Company Manager" || value === 'Company User' || value === 'Company Admin')) {
          const filterCompanies = companyManagement?.filter(companyName => companyName?.monitor_company_check === false);
          setCompanyOptionsDup(filterCompanies);

        } else if ((value === 'Monitor Company User' || value === 'Monitor Company Admin')) {
          const filterCompanies = companyManagement?.filter(companyName => companyName?.monitor_company_check === true)
          setCompanyOptionsDup(filterCompanies);
        }
        else {
          setCompanyOptionsDup(companyOptions);
        }
        // var index = user_role_opt.indexOf({ id: "iCAM Company", label: "iCAM Company" });
        // if (index > -1) {
        //   user_role_opt.splice(index, 1);
        // }
        if (userRole?.toLowerCase() !== 'company user' && userRole?.toLowerCase() !== 'company admin') {
          changeDropdownValue("company_name",
            { id: '', label: '', vehicle_group: null, monitor_companys: null }
          )
        }
      }
    }
    if (type === "company_name") {
      var vehcilegroupOptions = [...vehicleOptions]
      if ((formdata.role === "Company Manager" || formdata.role === 'Company User' || formdata.role === 'Company Admin')) {
        var vehicleSplit = e?.companyVehicle ? e?.companyVehicle.split(',') : []
        const filterVehicleGroups = vehcilegroupOptions.filter((vehiclegroup) => {
          return vehiclegroup?.vehicle_group && vehiclegroup?.vehicle_group?.split(',').some(r => vehicleSplit.indexOf(r) > -1)
        })
        setvehicleOptionsDuplicate(filterVehicleGroups)
      }
      else if ((formdata.role === 'Monitor Company User' || formdata.role === 'Monitor Company Admin')) {
        var companysplit = e?.monitor_companys ? e?.monitor_companys : {}
        companysplit = Object.keys(companysplit)
        var companyopt = []
        companysplit?.map((cmp) => {
          companyopt?.push({
            id: cmp,
            label: cmp
          })
        })
        setSubcompanyOptionsDuplicate(companyopt)
      }
    }
  }

  function getSelectedItem(
    id,
    data = [],
    multiple = '',
    label = '',
    is_inputvalue = ''
  ) {
    if (multiple) {
      var items = []
      // id = typeof (id) === 'string' ? id.split(',') : id
      id = typeof id === 'string' ? id.split(',') : id
      id?.map((sid, i) => {
        const item = data.find((opt) => {
          if (label) {
            if (opt.label == sid) return opt
          } else {
            if (opt.id == sid) return opt
          }
        })
        if (item) {
          items.push(item)
        }
      })
      return items
    } else {
      const item = data.find((opt) => {
        if (label) {
          if (opt.label == id) return opt
        } else {
          if (opt.id == id) return opt
        }
      })
      return item || null
    }
  }
const isvehiclegroupshow =
  formdata?.role === 'iCAM Admin' ||
  formdata?.role === 'iCAM User' ||
  formdata?.role === 'Company Admin' ||
  formdata?.role === 'Monitor Company User' ||
  formdata?.role === 'Monitor Company Admin';
    return is_edit_loaded ? (
    <>
      <AlertMessage
        alert={alert}
        alertMessage={alertMessage}
        confirm={confirm}
        alertType={alertType}
      />
      <Dialog
        open={openUserPopUp}
        onClose={onCloseUserPopup}
        aria-labelledby="form-dialog-title"
        aria-describedby="modal-modal-description"
        fullWidth
        maxWidth="md"
      >
        <ValidatorForm
          onSubmit={handleSubmitSearch}
          onError={() => null}
          className=""
        >
          <DialogTitle
            className="adduserManagementHeader"
            // id="form-dialog-title"
            onClose={onCloseUserPopup}
          >
            {/* monitor_company_check */}
            {userViewType === "Add" ? "Add" : userViewType === "Edit" ? "Edit" : "View"}{" "}
            User
            <img
              src={CloseBtn}
              alt=""
              className="discard-close-pointer"
              onClick={onCloseUserPopup}
            />
          </DialogTitle>

          <DialogContent className="pad-l-r-t-b">
            <div>
              <Grid container md={12} className="user-add-block">
                <Grid
                  spacing={5}
                  className="search-form-main align-items-center"
                >
                  <Grid
                    item
                    md={12}
                    className="search-form-block text-center respo-w-100 respo-t-center p-relative"
                  >
                    <div className="circular-image">
                      <img
                        className="circular-image-img"
                        alt=""
                        // src={previewImg ? previewImg : userLogobg}
                        src={
                          previewImg ? previewImg : formdata?.logo && formdata?.logo != imgUrl ? formdata?.logo : User_Icon
                        }
                      />
                    </div>
                    <img
                      style={{
                        position: "relative",
                        top: "-50px",
                        left: "-115px",
                      }}
                      alt=""
                    // src={cameraIcon}
                    />
                    <div
                      className="img-upload marg-0-auto upload-logo "
                      style={{ marginTop: "-100px", marginLeft: "260px" }}
                    >
                      {!disabled && <>
                      <input
                        // disabled={disabled}
                        // className={`insert-img pointer border-0 ${disabled ? 'disabled' : ''}`}
                        type="file"
                        name="logo"
                        id="logo"
                        className="insert-img pointer border-0"
                        onChange={(e) => onFileChange(e)}
                        accept="image/png, image/jpeg, image/gif, image/svg"
                      />
                      </>}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                className="search-form-main"
                sx={{
                  marginTop: "50px",
                  backgroundColor: "#F3F3F3",
                  paddingRight: "80px",
                  borderRadius: "7px",
                }}
              >
                <Grid item md={12} className="search-form-block mar-b-24">
                  <label className="font-16 font-600">User Details</label>
                </Grid>
                <Grid item md={6} className="pad-t-0">
                  <label>User Name <b className="redastrik">*</b></label>
                  <TextField
                    type='text'
                    name='user_name'
                    id='user_name'
                    label='Enter User Name'
                    placeholder='Enter User Name'
                    value={formdata.user_name}
                    onChange={(e) => formdataValueChange(e)}
                    // className='search-email gm-t-10 custom-input'
                    className="search-email gm-t-10 custom-input dark-grey-bg dark-grey-color"
                    disabled={disabled}
                    errorMessages={["This field is required*"]}
                    validators={["required"]}
                  />
                </Grid>
                <Grid item md={6} className="user-add-block pad-t-0 multiple">
                  <label>User Role <b className="redastrik">*</b></label>
                  <AutoComplete
                    disabled={disabled}
                    className='dropdown gm-t-10 w-100 mar-b-0'
                    fullWidth
                    options={user_role_opt}
                    defaultValue={getSelectedItem(
                      formdata.role,
                      user_role_opt
                    )}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) =>
                      changeDropdownValue("role", value)
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // className='search-dropdown custom-input border-none'
                        className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                        label='Select'
                        name='role'
                        placeholder='Select'
                        value={formdata.role}
                        errorMessages={["This field is required*"]}
                        validators={["required"]}
                      />
                    )}
                  />
                </Grid>
                {isCompanyLoading && <Grid item md={6}
                  className="user-add-block pad-t-0 multiple">
                  <label>Company <b className="redastrik">*</b></label>
                  {formdata.role === 'iCAM Admin' || formdata.role === 'iCAM User' ? (<TextField
                    disabled={disabled}
                    type='text'
                    name='Company'
                    id='Company'
                    placeholder='Enter Company Name'
                    value={formdata.company_name}
                    onChange={(e) => formdataValueChange(e)}
                    className="search-email gm-t-10 custom-input dark-grey-bg dark-grey-color"
                  />) : (<AutoComplete
                    // disabled={isViewEnable ? (formdata.role === 'iCAM Admin' || formdata.role === 'iCAM User') : ""}
                    disabled={disabled}
                    id="disabled-options-demo"
                    className='dropdown gm-t-10 w-100 mar-b-0'
                    fullWidth
                    options={companyOptionsDuplicate}
                    defaultValue={getSelectedItem(
                      formdata.company_name,
                      companyOptionsDuplicate
                    )}
                    value={{ label: formdata.company_name, id: formdata.company_name }}
                    onChange={(event, value) =>
                      changeDropdownValue("company_name", value)
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                        label='Company'
                        name='company_name'
                        placeholder='Company'
                        value={formdata.company_name}
                        errorMessages={["This field is required*"]}
                        validators={["required"]}
                      />
                    )}
                  />)
                  }
                </Grid>}
                {(formdata.role === 'Monitor Company User') ?
                  <Grid item md={6}
                    className="user-add-block pad-t-0 multiple">
                    <label>Sub Company <b className="redastrik">*</b></label>
                    <AutoComplete
                      disabled={disabled}
                      className='dropdown gm-t-10 w-100 mar-b-0'
                      fullWidth
                      options={subcompanyOptionsDuplicate}
                      defaultValue={getSelectedItem(
                        formdata.subCompany_name,
                        subcompanyOptionsDuplicate
                      )}
                      onChange={(event, value) =>
                        changeDropdownValue("subCompany_name", value)
                      }
                      filterSelectedOptions
                      value={formdata.subCompany_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                          label='Sub Company'
                          name='subCompany_name'
                          placeholder='Sub Company'
                          errorMessages={formdata.subCompany_name === null}
                          validators={formdata.subCompany_name === null ? 'This field is required*' : ''}
                        />
                      )}
                    />
                  </Grid> : null}
                {/* <Grid item md={6} className="user-add-block pad-t-0 multiple">
                  <label>Status <b className="redastrik">*</b></label>
                  <AutoComplete
                    disabled={disabled}
                    className='dropdown gm-t-10 w-100 mar-b-0'
                    fullWidth
                    options={status_opt}
                    disableClearable
                    defaultValue={getSelectedItem(
                      formdata.status,
                      status_opt
                    )}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) =>
                      changeDropdownValue("status", value)
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // className='search-dropdown custom-input border-none'
                        className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                        label='Select'
                        name='status'
                        placeholder='Select'
                        value={formdata.status}
                        errorMessages={["This field is required*"]}
                        validators={["required"]}
                      />
                    )}
                  />
                </Grid> */}
                <Grid item md={6} className="pad-t-0">
                  <label>Email ID <b className="redastrik">*</b></label>
                  <TextField
                    disabled={disabled}
                    type='text'
                    name='email'
                    label='Enter Email ID'
                    placeholder='Enter Email ID'
                    value={formdata.email}
                    onChange={(e) => emailValueChanges(e)}
                    className="search-email gm-t-10 custom-input dark-grey-bg dark-grey-color"
                    errorMessages={["This field is required*"]}
                    validators={["required"]}
                  />
                  {error && <div style={{ color: "red", marginTop: "-16px" }} className="error-message">{error}</div>}
                </Grid>
                <Grid
                  item
                  md={6}
                  className="user-add-block pad-t-0 multiple">
                  <label>Event Action Group <b className="redastrik">*</b></label>
                  {isEventActionLoading && <AutoComplete
                    disabled={disabled}
                    limitTags={1}
                    className='dropdown gm-t-10 w-100 mar-b-0'
                    fullWidth
                    multiple
                    defaultValue={getSelectedItem(
                      formdata.user_group,
                      usergroup, 'multiple', 1
                    )}
                    onChange={(event, value) =>
                      changeDropdownValue("user_group", value)
                    }
                    options={usergroup}
                    filterSelectedOptions
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // className='search-dropdown custom-input border-none'
                        className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                        label='Event Action Group'
                        name='user_group'
                        placeholder='Event Action Group'
                        value={formdata.user_group}
                        errorMessages={["This field is required*"]}
                        validators={["required"]}
                      />
                    )}
                  />}
                </Grid>
                {isvehiclegroupshow ? null :
                  <Grid item md={6} className="user-add-block pad-t-0 multiple" >
                    <label>Vehicle Group <b className="redastrik">*</b></label>
                    {isVehicleLoading && <AutoComplete
                      disabled={disabled}
                      limitTags={1}
                      className='dropdown gm-t-10 w-100 mar-b-0'
                      fullWidth
                      options={vehicleGroupOpt}
                      defaultValue={getSelectedItem(
                        formdata.vehicle_group,
                        vehicleGroupOpt, 'multiple', 1
                      )}
                      multiple
                      getOptionLabel={(option) => option.label}
                      onChange={(event, value) =>
                        changeDropdownValue("vehicle_group", value)
                      }
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // className='search-dropdown custom-input border-none'
                          className="search-dropdown custom-input dark-grey-bg dark-grey-color border-none"
                          label='Select'
                          name='vehiclegroup'
                          id='vehiclegroup'
                          placeholder='Select'
                          value={formdata.vehicle_group}
                          // value={['Sam1 Company s3']}

                          errorMessages={["This field is required*"]}
                          validators={["required"]}
                        />
                      )}
                    />}
                  </Grid>}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions sx={{ marginRight: '25px' }}>
            <>
              {!disabled &&
                <Button
                  variant="contained"
                  color="primary"
                  className="whitebg custom-btn"
                  type="submit"
                >
                  SAVE
                </Button>}
              <Button
                variant="contained"
                color="primary"
                className="whitebg custom-btn-sec"
                onClick={onCloseUserPopup}
              >
                Back
              </Button>
            </>
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    </>
  ) : (
    ""
  );
};

export default AddUserManagement;
