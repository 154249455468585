import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterEventFieldApi, getEventAccData, getEventDataApi, getAllEvenetDataAPI, getEventVideoData,getEventManualData } from "../../Services/Event";
import React, { useState } from 'react';
const userId = localStorage.getItem("currentUser");

export const getEventFilter = createAsyncThunk("events/filter", async () => {
    try {
        let res = await filterEventFieldApi()
        if (res?.data?.length) {
            let data = res?.data[0]
            let allData = {}
            for (const key in data) {
                if (Array.isArray(data[key])) {
                    let label = data[key].map((field) => {
                        field = {
                            label: field,
                            value: field
                        }
                        return field
                    })
                    allData[key] = label
                }
            }

            return allData;

        }
    } catch (error) {
        console.log("Error", error)
    }
})
export const getEventData = createAsyncThunk("events/eventData", async (data, { rejectWithValue }) => {
    try {
        let res = await getEventDataApi(data)
        if (res.status === 200) {
            return {
                data: res.data,
                priority: data.priority
            }
        } else {
            return {
                data: [],
                priority: false
            }
        }

    } catch (error) {
        rejectWithValue(error)
        console.log(error)
        return {
            data: [],
            priority: false
        }
    }
})

export const getAccidentData = createAsyncThunk("events/accident", async (data, { rejectWithValue }) => {
    try {
        let res = await getEventAccData()
        if (res.status === 200) {
            return res?.data;
        } else {
            return []
        }

    } catch (error) {
        rejectWithValue(error)
        console.log(error);
        return []
    }
})
export const getVideoEventData = createAsyncThunk("events/video_event_filter", async (data, { rejectWithValue }) => {
    try {
        let res = await getEventVideoData()
        if (res.status === 200) {
            return res?.data;
        } else {
            return []
        }

    } catch (error) {
        rejectWithValue(error)
        console.log(error);
        return []
    }
})
export const getManualEventData = createAsyncThunk("event/manual_event_get_api/", async (data, { rejectWithValue }) => {
    try {
        let res = await getEventManualData()
        if (res.status === 200) {
            return res?.data;
        } else {
            return []
        }

    } catch (error) {
        rejectWithValue(error)
        console.log(error);
        return []
    }
})
export const getAllEventData = createAsyncThunk("event/event", async (data, { rejectWithValue }) => {
    try {
        let res = await getAllEvenetDataAPI()
        if (res?.status === 200) {
            if (Array.isArray(res?.data)) {
                // const filteredData = res?.data?.filter(item => !item.events_count);
                // const events_count = res?.data?.filter(item => item.events_count);
                // console.log("events_count",events_count);
                return res.data;
            }
            else {
                return res?.data;
            }
        } else {
            return []
        }
    }

    catch (error) {
        rejectWithValue(error)
        console.log(error);
        return []
    }
})


export const EventFilterFieldsSlice = createSlice({
    name: "events",
    initialState: {
        data: [],
        isData: false,
        isEventData: false,
        isVideoEvent: false,
        eventData: {},
        accidentData: [],
        eventsData: [],
        videoEventData: [],
        isAccident: false,
        isAllData: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVideoEventData.fulfilled, (state, { payload }) => {
            state.videoEventData = payload
            state.isVideoEvent = true;
        })
        builder.addCase(getVideoEventData.rejected, (state, { payload }) => {
            state.isVideoEvent = false;
        })
        builder.addCase(getManualEventData.fulfilled, (state, { payload }) => {
            state.ManualEventData = payload
            state.isManualEvent = true;
        })
        builder.addCase(getManualEventData.rejected, (state, { payload }) => {
            state.isManualEvent = false;
        })
        builder.addCase(getEventFilter.pending, (state, { payload }) => {
            state.isData = false;
        })
        builder.addCase(getEventFilter.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.isData = true;
        })
        builder.addCase(getEventFilter.rejected, (state, { payload }) => {
            state.isData = false;
        })
        builder.addCase(getEventData.fulfilled, (state, { payload }) => {

            state.eventData = { ...state.eventData, [payload.priority]: payload.data }
            state.isEventData = { ...state.isEventData, [payload.priority]: true }
        })
        builder.addCase(getEventData.rejected, (state, { payload }) => {
            state.isEventData = { ...state.isEventData, [payload.priority]: false }
        })
        builder.addCase(getAccidentData.fulfilled, (state, { payload }) => {
            state.accidentData = payload
            state.isAccident = true;
        })
        builder.addCase(getAccidentData.rejected, (state, { payload }) => {
            state.isAccident = false;
        })
        builder.addCase(getAllEventData.fulfilled, (state, { payload }) => {
            state.eventsData = payload
            state.isAllData = true;
        })
        builder.addCase(getAllEventData.rejected, (state, { payload }) => {
            state.isAllData = false;
        })


    }
})