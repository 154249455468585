import React from "react";
import "../../Assets/css/style.css";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
export const Loader = (props) => {
  const videoStyle=props.videoEvents ==='videoEvents' ? "loading-overlay-video":"loading-overlay"
  return (
    <div className={videoStyle} style={props.nooverlay && {backgroundColor:"unset"}}>
      <div className="loading-icon">
        <Box sx={{ display: "flex", position: "relative" }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
            }}
            size={40}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: (theme) =>
                theme.palette.mode === "light" ? "#2e386b" : "#308fe8",
              animationDuration: "2s",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={40}
            thickness={4}
            {...props}
          />
        </Box>
      </div>
    </div>
  );
};
