import axiosInstance from "./axios";

export default {
    async get(endpoint) {
        const response = await axiosInstance.get(
            `${endpoint}`,
        );
        return response;
    },
    async post(endpoint, data) {    
        const response = await axiosInstance.post(
            `${endpoint}`,
            data,
        );
        return response;
    },
    async put(endpoint, data) {    
        const response = await axiosInstance.put(
            `${endpoint}`,
            data,
        );
        return response;
    }
}