import React, { useState, useEffect } from 'react';
import { Box, Grid, Table, TableHead, TableCell, TableBody, TableRow, Button, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/system'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Autocomplete } from '@mui/lab';
import { vehicleList, vehicleDelete, ResetPassword } from '../../Services/api';
import { getDataFromApi, deleteDataFromApi, putDataFromApi } from '../../Services/CommonServices';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import MainLogo from "../../Assets/images/login-logo.png";
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment'
import Icon_AccountCircle from '../../Assets/images/User_icon.svg'
import Icon_padlock1 from '../../Assets/images/lock.svg'
import Banner1 from '../../Assets/images/login-1.png'
import Banner2 from '../../Assets/images/login-2.svg'
import Banner3 from '../../Assets/images/login-3.svg'
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { postDataFromApi } from "../../Services/CommonServices"
import { userManagementGet, login } from "../../Services/api"
import { changePassword } from "../../Services/api"
import { Card } from '@mui/material'



const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const TextField = styled(TextValidator)(() => ({
    width: '100%',
    marginBottom: '16px',
}))

const ChangePassword = ({setOpen}) => {
    const navigate = useNavigate();
    const [allUser, setallUser] = useState([])
    const [isError, setisError] = useState(false)
    const [formdata, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
    });
    function formdataValueChange(e) {
        var value = e.target.value.trimStart();
        setFormData((formData) => ({
            ...formData,
            [e.target.name]: value,
        }));

    }
    const handleFormSubmit = async () => {
        var response = ''
        if (formdata.oldPassword && formdata.newPassword) {
            var body = {
                email:localStorage.getItem('useEmail'),
                old_password: formdata.oldPassword,
                password: formdata.newPassword,
                repassword: formdata.newPassword
            }
            response = await postDataFromApi(ResetPassword, body)
            if (response && response.status == 200 && response.data != null) {
                alert("Password changed successfully back to Login")
                localStorage.clear();
                navigate('/login')
            }
        } else {
            setisError(true)
        }
    }
    const StyledCard = styled(Card)(({ theme }) => ({
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',

        background: '#0E0E23',

    }))

    return (
        <>
            {/* <Box className='main-box mar-t-24 white-bg pad-r-0 loginbox ddd'>
                <Grid container spacing={3} className='main-grid justify-content-center'>
                    <Grid item md={4} className='main-title leftside align-self-center'>
                        <title>Change Password</title>
                        <ValidatorForm onSubmit={handleFormSubmit} onError={() => null}>
                            <label>Old Password</label>
                            <TextField
                                defaultValue=""
                                className="inputwithIcon"
                                sx={{ mb: '12px', width: '100%' }}
                                label="Password"
                                variant="outlined"
                                size="small"
                                onChange={(e) => formdataValueChange(e)}
                                name="oldPassword"
                                type="password"
                                value={formdata.oldPassword || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                InputProps={{
                                    autocomplete: 'old-password',
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={Icon_padlock1} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <label>New Password</label>
                            <TextField
                                defaultValue=""
                                className="inputwithIcon"
                                sx={{ mb: '12px', width: '100%' }}
                                label="Password"
                                variant="outlined"
                                size="small"
                                onChange={(e) => formdataValueChange(e)}
                                name="newPassword"
                                type="password"
                                value={formdata.newPassword || ''}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                InputProps={{
                                    autocomplete: 'new-password',
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={Icon_padlock1} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <FlexBox
                                flexWrap="wrap"
                                sx={{
                                    justifyContent: 'space-between',
                                    maxWidth: 400,
                                }}
                            >

                            </FlexBox>
                            <FlexBox mb={2} flexWrap="wrap">
                                <Box
                                    position="relative"
                                    className="login-button"
                                >
                                    <Button
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Box>

                            </FlexBox>
                        </ValidatorForm>
                        {isError ? <div className="errors">Invalid Login Details</div> : ''}
                    </Grid>

                </Grid>
            </Box> */}
            < Dialog
                open={true}
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="form-dialog-title"
            >

                <Box className='main-box mar-t-24 white-bg pad-r-0 loginbox ddd'>
                    <Grid container spacing={3} className='main-grid justify-content-center'>
                        <Grid item md={12} className='main-title leftside align-self-center'>
                            <title style={{textAlign:"center"}}>RESET PASSWORD</title>
                            <ValidatorForm onSubmit={handleFormSubmit} onError={() => null}>
                                <label>Old Password</label>
                                <TextField
                                    defaultValue=""
                                    className="inputwithIcon"
                                    sx={{ mb: '12px', width: '100%' }}
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => formdataValueChange(e)}
                                    name="oldPassword"
                                    type="password"
                                    value={formdata.oldPassword || ''}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    InputProps={{
                                        autocomplete: 'old-password',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={Icon_padlock1} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <label>New Password</label>
                                <TextField
                                    defaultValue=""
                                    className="inputwithIcon"
                                    sx={{ mb: '12px', width: '100%' }}
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => formdataValueChange(e)}
                                    name="newPassword"
                                    type="password"
                                    value={formdata.newPassword || ''}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    InputProps={{
                                        autocomplete: 'new-password',
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={Icon_padlock1} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FlexBox
                                    flexWrap="wrap"
                                    sx={{
                                        justifyContent: 'space-between',
                                        maxWidth: 400,
                                    }}
                                >

                                </FlexBox>
                                <FlexBox mb={2} style={{ alignSelf: "flex-start"}} >
                                    <Box
                                        position="relative"
                                        className="login-button"
                                        style={{margin:'5px'}}
                                    >
                                        <Button
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            type="button"
                                            onClick={()=>setOpen(false)}
                                        >
                                            Back
                                        </Button>
                                       
                                    </Box>
                                    <Box
                                        position="relative"
                                        className="login-button"
                                        style={{margin:'5px'}}

                                    >
                                        <Button
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                       
                                    </Box>

                                </FlexBox>
                                {isError ? <div className="errors">Invalid Login Details</div> : ''}
                            </ValidatorForm>
                        </Grid>

                    </Grid>
                </Box>
            </Dialog>
        </>
    )
}

export default ChangePassword;