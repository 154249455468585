import { MainUrl } from './CommonServices';
/*User Management*/
export const userManagementGet = '/user/user/';
export const userManagementList = '/user/user/';
export const userManagementAdd = '/user/user/';
export const userManagementDelete = '/user/user/';
export const userManagementUpdate = '/user/user/';
export const userGroup = '/user/usergroup/';
export const userPicUpdate = '/user/userpic_update/';
export const deleteUserGroups = '/user/usergroup/'
export const userGroupUpdate = '/user/usergroup/'
export const userGroupAdd = '/user/usergroup/'
export const driverGroupss = "/driver/drivergroup/";
/*Company Management*/
export const companyManagementList = '/company/company/';
export const companyManagementAdd = '/company/company/';
export const companyManagementUpdate = '/company/company/';
export const companyManagementDelete = '/company/company/';
export const companyManagementGet = '/company/company/';
export const companylogoupdate = '/company/logo_update/';
///*Monitoring Company*/
export const monitoringCompanyAdd ='/company/monitor_company/';
/*Driver*/
export const driverGet = '/driver/driver/';
export const driverList = '/driver/driver/';
// http://ec2-3-84-8-100.compute-1.amazonaws.com:8000/api/driver/user_role_based_drivers/42/
export const driverFormList = '/driver/user_role_based_drivers/';
export const driverUpdate = '/driver/driver/';
export const driverCreate = '/driver/driver/';
export const driverDelete = '/driver/driver/';
export const driverAdd = '/driver/driver/';
export const createDriverGroup = '/driver/drivergroup/';
export const driverdocupdate = '/driver/driverdoc_update/';
export const driverpicupdate = '/driver/driverpic_update/';
export const driverGroupFilter = '/driver/drivergroupfilter/';
export const deleteDriverGroup = '/driver/drivergroup/'
export const driverGroupUpdate = '/driver/drivergroup/'
/*Vehicle*/
export const vehicleList = '/vehicle/vehicle/';
// http://ec2-3-84-8-100.compute-1.amazonaws.com:8000/api/vehicle/user_role_based_vehicles/33/
export const vehicleFormlist = '/vehicle/user_role_based_vehicles/';
export const vehicleView = '/vehicle/vehicle/';
export const vehicleAdd = '/vehicle/vehicle/';
export const vehicleUpdate = '/vehicle/vehicle/';
export const vehicleDelete = '/vehicle/vehicle/';
export const createVehicleGroup = '/vehicle/vehiclegroup/';
export const vehicleGroupFilter = '/vehicle/vehiclegroupfilter/';
export const vehicleChangeGroup = '/vehicle/vehiclegroup_update/';
export const vehiclesgroupss = "/vehicle/vehiclegroup/"
/*Event Designer*/
export const eventworkflowList = '/workflow/workflowloginid/';
export const eventworkflowAdd = '/workflow/workflow/';
export const eventworkflowGet = '/workflow/workflow/';
export const eventworkflowUpdate = '/workflow/workflowstep/';
export const eventworkflowDelete = '/workflow/workflowstep/';
export const coachingeventworkflowDelete = '/coaching/coaching_workflowstep/';

export const eventworkflowStepAdd = '/workflow/workflowstep/';
export const eventworkflowStepList = '/workflow/workflowstep/';
/* WorkFlow Steps */
export const workflowStepList = '/workflow/workflowstep/'
export const coachingworkflowStepList = '/coaching/coaching_workflowstep/'
export const workflowStepListApi = '/workflow/workflowstep/'
export const createWorkFlow = '/workflow/workflowstep/'

/* Coaching WorkFlow Steps */
export const coachingcreateWorkFlow = '/coaching/coaching_workflowstep/'

export const eventaddWorkflow='/event/event_custom_worksteps/'


/*Event*/
export const eventCreation='/event/event_creation/'
export const filterEvent = './event/filter_status/'
export const eventList = '/event/event/';
export const eventNext = '/event/event/';
export const eventAll = '/event/event/';
export const eventDetail = '/event/eventlist/';
export const hoverSecondary = '/event/secondary_events/'
export const eventCreate = '/event/event/';
export const eventUpdate = '/event/event/';
export const eventDelete = '/event/event/';
export const eventEscalation = '/event/eventescalation/';
export const discardEvent = '/event/event_discard/';
export const eventDiscard = '/event/eventdiscard/';
export const eventDropDownList = '/event/Item_event_type/'
export const eventAccident = '/event/accidentevents/';
export const singleEventDetails = '/event/eventdetails/';
export const viewMapDetails = '/event/eventdetails/'
export const latestEvents = '/event/latestevents/';
export const eventAction = '/event/eventaction/';
export const workflowFilter = '/workflow/workflowfilter/';
export const getEventTakeAction = '/event/eventactionupdate/'
export const eventWorkflow = '/event/eventworkflow/';
export const eventWorkflowStep = '/event/eventworkflowstep/';
export const eventActionUpdate = '/event/eventactionupdate/';
export const eventAutuUpdate = "/event/eventautoupdate/"
export const priorityEvents = '/event/priority_events/';
export const addEvents = '/event/event_post/'
export const EventWorkflowMgmtApi = '/event/event_workflow_management/'
//eventdesigner
export const eventDesigner = '/event/event_designer_filters/';
/*Vehicle Group*/
export const vehicalGroupList = '/vehicle/vehiclegroup/';
export const vehicalGroupList2 = '/vehicle/vehiclegroupdetails/';
export const newVehicleGroup = '/vehicle/vehiclegroupdetails/'
export const vehicleGroupAdd = '/vehicle/vehiclegroupdetails/'
export const vehicleDeleteGroup = '/vehicle/vehiclegroupdetails/'
/*driver Group*/
export const driverGroup = '/driver/drivergroup/';
export const driverGroup2 = '/driver/drivergroupdata/';
/* Dashboard */
export const totalDriver = '/dashboard/totaldriver/';
export const totalVehicle = '/dashboard/totalvehicle/';
export const totalEvent = '/dashboard/totalevent/';
export const totalCompany = '/dashboard/totalcompany/';
export const dashboardCompany = '/dashboard/company/';
export const dashboardUser = '/dashboard/user/';
export const dashboardEvent = '/dashboard/event/';
/* company dashboard */
export const eventCount = '/company/event_count_with_company_name/';
export const vehicleCount = '/company/vehicle_count_with_company_name/';
export const driverCount = '/company/driver_count_with_company_name/';
export const vehicleListWithCompany = '/company/vehicle_list_with_company_name/';
export const eventListWithCompany = '/company/event_list_with_company_name/';
export const driverListWithCompany = '/company/driver_list_with_company_name/';
/*event inbox*/
export const eventInbox = '/event/event_inbox/';
export const coachingActionReverseSteps ='/coaching/coaching_event_custom_worksteps/'
/*login*/
export const login = '/authentication/login/';
export const loginNew = '/user/login/';
export const userLogin = '/authentication/login/'
export const changePassword = '/authentication/changepassword/'
export const ForgetPassword = '/user/forgot-password/'
export const ResetPassword = "/user/reset-password/"
//Reports
export const DriverReports = "/event/driver_name_reports/"
export const vehicleReports = "/event/vechile_name_reports/"
export const UserReports = "/event/username_reports/"
//hover
export const hoverListing = "/event/secondary_events/"
//months
export const monthlisting = "/event/reports_percentages/"
export const userReports = "/event/user_reports_percentages/"
//Event Mnagaement
export const workFlowFilter = "/event/auto_workflow_for_selected_events/"
//coaching Management
export const coachingFilter = "/coaching/coaching_workflow_designer_filters/"
export const addWorkFlow = "/coaching/coaching_workflow/"
export const coachingTypeFilter = "/coaching/coaching_workflow_filter/"
export const coachingWorkFlow = "/coaching/coaching_event_workflow_management/"
export const eventTab = "/coaching/coaching_event_filter_status/"
export const autoCoachingWorkflow = "/coaching/auto_workflow_for_selected_coaching_events/"
export const coachingEvent = "/coaching/coaching_event_filter/"
export const coachingEventInbox = "/coaching/coaching_event_inbox/"
export const coachingSearchFilter = "/coaching/coaching_workflow_search/"
export const coachingEventSearchFilter = "/coaching/coaching_event_search/"
export const autoworkflowCoaching = "/coaching/coachingeventautoupdate/"
export const customCoachingAdd="/coaching/coaching_event_custom_worksteps"
//eventuserpagination
export const userrolesEventPagination = "/event/user_role_based_events/"
export const eventsPaginationByPriority = "/event/priority_events_pagn/"
export const vedioEventsPagination ="/event/video_event_filter/"
//Manaul event 

export const ManualEvent="/event/manual_event_get_api/"
//Event Type
export const eventTypeList="/event/event_type_priority_category_creation/"
export const eventTypetagList="/event/event_type/"
export const get_driver_namesListApi="/event/get_driver_names/"
export const update_driver_viewAPI="event/update_driver_view/"


export const event_seq_id_drp_getAPI="/event/event_seq_id_drp_get/"
export const event_seq_id_next_scrollAPI="/event/event_seq_id_next_scroll/"
export const event_seq_id_suggestAPI="/event/event_seq_id_suggest/"
export const audit_log_by_event_idAPI="/event/audit_log_by_event_id/"
export const event_type_by_company_nameAPI="/event/event_type_by_company_name/"
