import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getFilterFieldsApi } from "../../Services/reports";

export const getFilterFieldsSlice = createAsyncThunk(
    "reports/getFilds",
    async (value,{rejectWithValue}) => {
        try {

            const res = await getFilterFieldsApi()
            if (res.data.length > 0) {
                let obj = res.data[0];
                let dataObj = {}
                for (let prop in obj) {
                    if (Array.isArray(obj[prop])) {
                        let modifiedArr = obj[prop].map((datas) => {
                            datas = { label: datas }
                            return datas
                        })
                        dataObj[prop] = modifiedArr
                    }
                }


                return dataObj;
            }
        } catch (error) {
            console.log("api error", error)
            return rejectWithValue(error);
        }
    }
)

export const filterFieldsSlice = createSlice({
    name: "reports",
    initialState: {
        data: [],
        isData: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFilterFieldsSlice.pending, (state, { payload }) => {
            // state.data = payload;
            state.isData = false;
        })
        builder.addCase(getFilterFieldsSlice.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.isData = true;
        })
        builder.addCase(getFilterFieldsSlice.rejected, (state, { payload }) => {
            // state.data = payload;
            state.isData = false;
        })
    }
})