import React, { lazy, Suspense } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { render } from "react-dom";
import NotFound from "./Components/ErrosPages/404";
import { Loader } from "./Common/Loader";
import EventType from "./Components/EventType/EventType";

const LoginIndex = lazy(() => import("./Components/login"));
const DashboardIndex = lazy(() => import("./Components/Dashboard/index"));
const EventsIndex = lazy(() => import("./Components/Events/index"));
const ViewEventsIndex = lazy(() => import("./Components/Events/view_events"));
const VehiclesIndex = lazy(() => import("./Components/Vehicles/index"));
const VehiclesView = lazy(() => import("./Components/Vehicles/view_vehicle"));
const AddVehicleIndex = lazy(() => import("./Components/Vehicles/add_vehicle"));
const DriversIndex = lazy(() => import("./Components/Drivers/index"));
const AddDriver = lazy(() => import("./Components/Drivers/add_Driver"));
const ViewDriver = lazy(() => import("./Components/Drivers/view_driver"));
const VehicleinformationIndex = lazy(() => import("./Components/VehicleInformation/index"));
const InboxIndex = lazy(() => import("./Components/Inbox/index"));
const CoachingInbox = lazy(() => import("./Components/Inbox/coachingInbox"));
const CoachingEventIndex = lazy(() => import("./Components/CoachingManagement/CoachingEvent"));
const MainComponent = lazy(() => import("./Components/CoachingDesigner/CoachingWorkflowStep"));
const Notification = lazy(() => import("./Components/UserNotification/notification"));
const ReportsByDriver = lazy(() => import("./Components/Reports/report_by_driver"));
const ReportsByUser = lazy(() => import("./Components/Reports/report_by_user"));
const ReportsByVehicle = lazy(() => import("./Components/Reports/report_by_vehicle"));
const CoachingIndex = lazy(() => import("./Components/CoachingManagement"));
const EventIndex = lazy(() => import("./Components/EventManagement"));
const EventdesignerIndex = lazy(() => import("./Components/EventDesigner/index"));
const WorkFlowSteps = lazy(() => import("./Components/EventDesigner/workFlowSteps"));
const AddWorkFLowSteps = lazy(() => import("./Components/EventDesigner/addWorkFLowSteps"));
const AddEventdesignerIndex = lazy(() => import("./Components/EventDesigner/add_eventdesigner"));
const UserManagement = lazy(() => import("./Components/UserManagement/index"));
const CompanyManagement = lazy(() => import("./Components/CompanyManagement/index"));
const DashboardCompanyManagement = lazy(() => import("./Components/CompanyManagement/dashboard"));
const ViewCompanyManagement = lazy(() => import("./Components/CompanyManagement/view_company"));
const AddCompanyManagement = lazy(() => import("./Components/CompanyManagement/add_company"));
const AddUserManagement = lazy(() => import("./Components/UserManagement/add_user"));
const ViewUserManagement = lazy(() => import("./Components/UserManagement/view_user"));
const FNOLIndex = lazy(() => import("./Components/FNOL/index"));
const EventP0 = lazy(() => import("./Components/Events/eventP0"));
const Events = lazy(() => import("./Components/Events/events"));
const LiveVideoStream = lazy(() => import("./Components/Events/LiveVideoStream"));
const EventP1 = lazy(() => import("./Components/Events/eventP1"));
const EventP2 = lazy(() => import("./Components/Events/eventP2"));
const VideoEvents = lazy(() => import("./Components/Events/videoEvents"));
const ManualEvents = lazy(() => import("./Components/Events/ManualEvents"));
const EventP3 = lazy(() => import("./Components/Events/eventP3"));
const Accident = lazy(() => import("./Components/Events/accident"));
const Sensor = lazy(() => import("./Components/Events/sensor"));
const ForgotPassword = lazy(() => import("./Components/login/forgot_password"));
const ChangePassword = lazy(() => import("./Components/login/change_password"));

class MyRoutes extends React.Component {
  render() {
    return (
      <Suspense 
      // fallback={<div>{<Loader />}</div>}
      >
      <Routes>
        <Route path='/' element={<LoginIndex />} />
        <Route path='/dashboard' element={<DashboardIndex />} />
        <Route path='/events' element={<EventsIndex />} />
        <Route path='/events/view_events/:eventid' element={<ViewEventsIndex />} />
        <Route path='/vehicles' element={<VehiclesIndex />} />
        <Route path='/vehicles/view_vehicle/:vehicleid' element={<VehiclesView />} />
        <Route path='/vehicles/edit_vehicle/:vehicleid' element={<AddVehicleIndex />} />
        <Route path='/vehicles/add_vehicle' element={<AddVehicleIndex />} />
        <Route path='/drivers' element={<DriversIndex />} />
        <Route path='/drivers/add_driver' element={<AddDriver />} />
        <Route path='/drivers/view_driver/:driverid' element={<ViewDriver />} />
        <Route path='/drivers/edit_driver/:driverid' element={<AddDriver />} />
        <Route path='/vehicle_information/' element={<VehicleinformationIndex />} />
        <Route path='/vehicle_information/:vehicleid' element={<VehicleinformationIndex />} />
        <Route path='/inbox' element={<InboxIndex />} />
        <Route path='/coaching_inbox' element={<CoachingInbox />} />
        <Route path='/coaching_management/coachingEvent' element={<CoachingEventIndex />} />
        <Route path='/coaching_eventDesigner' element={<MainComponent />} />
        <Route path='/usernotification' element={<Notification />} />
        <Route path='/reports/report_by_driver' element={<ReportsByDriver />} />
        <Route path='/reports/report_by_user' element={<ReportsByUser />} />
        <Route path='/reports/report_by_vehicle' element={<ReportsByVehicle />} /> 
        <Route path='/coaching_management' element={<CoachingIndex />} />
        <Route path='/event_management' element={<EventIndex />} />
        <Route path='/event_designer' element={<EventdesignerIndex />} />
        <Route path='/event_type' element={<EventType />} />
        <Route path='/event_designer/workflowsteps' element={<WorkFlowSteps />} />
        <Route path='/event_designer/addWorkFLowSteps' element={<AddWorkFLowSteps />} />
        <Route path='/event_designer/edit_workFLowSteps/:eventid' element={<AddWorkFLowSteps />} />
        <Route path='/event_designer/view_workFLowSteps/:eventid' element={<AddWorkFLowSteps />} />
        <Route path='/event_designer/add_eventdesigner' element={<AddEventdesignerIndex />} />
        <Route path='/event_designer/edit_eventdesigner/:eventid' element={<AddEventdesignerIndex />} />
        <Route path='/event_designer/view_eventdesigner/:eventid' element={<AddEventdesignerIndex />} />
        <Route path='/user_management' element={<UserManagement />} />
        <Route path='/company_management' element={<CompanyManagement />} />
        <Route path='/company_management/dashboard/:company_name' element={<DashboardCompanyManagement />} />
        <Route path='/company_management/view_company/:companyid' element={<ViewCompanyManagement />} />
        <Route path='/company_management/add_company' element={<AddCompanyManagement />} />
        <Route path='/company_management/edit_company/:companyid' element={<AddCompanyManagement />} />
        <Route path='/user_management/add_user' element={<AddUserManagement />} />
        <Route path='/user_management/view_user/:user_id' element={<ViewUserManagement />} />
        <Route path='/user_management/edit_user/:user_id' element={<AddUserManagement />} />
        <Route path='/FNOL' element={<FNOLIndex />} />
        <Route path='/events/eventP0' element={<EventP0 />} />
        <Route path='/events/events' element={<Events/>}/>
        <Route path='/events/livestream' element={<LiveVideoStream />}/>
        <Route path='/events/eventP1' element={<EventP1 />} />
        <Route path='/events/eventP2' element={<EventP2 />} />
        <Route path='/events/videoevents' element={<VideoEvents />} />
        <Route path='/events/manualevents' element={<ManualEvents />} />
        <Route path='/videoevents' element={<VideoEvents />} />
        <Route path='/events/eventP3' element={<EventP3 />} />
        <Route path='/events/accident' element={<Accident />} />
        <Route path='/events/sensor' element={<Sensor />} />
        <Route path='/login' element={<LoginIndex />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/change-password' element={<ChangePassword />} />
        {/* <Route path='/404' element={<NotFound />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      </Suspense>
    )
  }
}

export default MyRoutes;
