import httpService from "./axiosIntance";
var userId = localStorage.getItem("currentUser");

export const getFilterFieldsApi = () => {
    userId = localStorage.getItem("currentUser");
    return httpService.get("/event/reports_filters/"+userId+'/')
}
export const filteringDataApiVehicle = (data) => {
    return httpService.post("/event/vechile_name_reports/", data)
}
export const filteringDataApiDriver = (data) => {
    return httpService.post("/event/driver_name_reports/", data)
}
export const filteringDataUser = (data) => {
    return httpService.post("/event/username_reports/", data)
} 
